import { Link } from "react-router-dom"
import heroSecion from "./assets/hero-image.png"
import heroSecionBanner from "./assets/hero-section-bg.jpg"
import LogoImage from "./assets/logo.png"
import ZayaraForumFooter from "./components/Footer"
import { ArrowIcon } from "./components/Icons"
import { forumGroupsList } from "./static-data"


export default function ZayaraForumLanding() {

  return (
    <main className="zayara-forum" dir="rtl">
      <section className="hero-section">
        <div className="hero-banner">
          <img src={heroSecionBanner} alt="umrah forum bg" height="644px" />
        </div>


        <div className="container">
          <img src={LogoImage} alt="صفا سوفت منتدي العمرة و الزيارة" className="img-fluid logo" width="450" />

          <div className="content-section row mx-0">
            <div className="align-self-center  col-md-6 col-12">
              <h1 className="d-flex flex-column flex-wrap gap-10">
                <span>شارك في مستقبل العمرة: </span>
                <span>مجموعات تركيز حصرية في المنتدى!</span>
              </h1>
              <p>انضم إلينا في منتدى العمرة والزيارة، نناقش أحدث الحلول التقنية وأبرز مجالات التحسين والتطوير</p>
              <span></span>
            </div>
            <img src={heroSecion} alt="منتدي العمرة و الزيارة" className="img-fluid hero-image col-md-6 col-12" />

          </div>
        </div>
      </section>


      <section className="landing-groups-container">
        <div className="container">
          <h2 className="title">مجموعات التركيز</h2>
          <p className="description">
            ندعوكم للمشاركة في مجموعات التركيز التي تجمع نخبة من الجهات الحكومية، وشركات العمرة، وخبراء التكنولوجيا، ورواد الأعمال لمناقشة فرص التحسين والتطوير في قطاع العمرة والسياحة
          </p>

          <ul className="groups-list my-5">
            {forumGroupsList.map(group => {
              return (
                <li className="item" key={group.url}>
                  <span className="icon">{group.icon}</span>
                  <h4 className="group-title">{group.title}</h4>
                  <Link to={`/umrah-forum/group/${group.url}`} className="d-flex justify-content-center align-items-center gap-10 text-center mt-3">
                    <span> عن مجموعة التركيز</span>
                    <ArrowIcon />
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </section>

      <ZayaraForumFooter />
    </main>
  )
}
