import React from "react"
import { Link } from "react-router-dom/cjs/react-router-dom"
import LogoImage from "../assets/white-logo.png"
import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon, YoutubeIcon } from "./Icons"

export default function ZayaraForumFooter({ group, hideButtons }) {
  return (
    <>
      {!hideButtons &&
        <div className="container" style={{ paddingBottom: "40px" }}>
          <div className="footer-links mb-5 position-relative">
            <p className="mb-4">إذا كنت ترغب في ترشيح خبراء لحضور مجموعات التركيز والمشاركة فيها أو ترغب في تسجيل الحضور لنفسك برجاء الضغط على أحد الاختيارات التالية</p>
            <div className="d-flex flex-wrap align-items-center justify-content-center gap-24">
              <Link to={`/umrah-forum/expert-form${group ? "?group=" + group : ""}`} className="btn main-btn">
                ارغب بترشيح خبراء متخصصين
              </Link>
              <Link to={`/umrah-forum/signup-form${group ? "?group=" + group : ""}`} className="btn main-btn">
                ارغب بتسجيل الحضور
              </Link>
            </div>
          </div>
        </div>
      }

      <footer className="page-footer py-3">
        <div className="container">
          <div className="d-flex flex-wrap justify-content-center justify-content-md-between align-items-center gap-24">

            <img src={LogoImage} alt="صفا سوفت منتدي العمرة و الزيارة" className="img-fluid logo" width="300" />

            <ul className="d-flex align-items-center justify-content-center gap-10">
              <li>
                <a href="https://www.facebook.com/Safasoftofficial" target="_blank" rel="noreferrer">
                  <span className="sr-only">Facebook Icon</span>
                  <FacebookIcon />
                </a>
              </li>
              <li>
                <a href="https://x.com/safa_soft" target="_blank" rel="noreferrer">
                  <span className="sr-only">Twitter Icon</span>
                  <TwitterIcon />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/safasoftofficial/" target="_blank" rel="noreferrer">
                  <span className="sr-only">Instagram Icon</span>
                  <InstagramIcon />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/safa-soft/" target="_blank" rel="noreferrer">
                  <span className="sr-only">LinkedIn Icon</span>
                  <LinkedInIcon />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCjWsZOOhf6QAry-92yT8_Dg" target="_blank" rel="noreferrer">
                  <span className="sr-only">Youtube Icon</span>
                  <YoutubeIcon />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  )
}
