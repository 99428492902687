import DateTimePickerField from "components/Form/DateTimePickerField/DateTimePickerField";
import React from "react";
import WathcIconsSrc from "assets/images/umrah-operations/watch-icon.svg";
import { ClockTimeIcon, PlaneIcon, PortIcon } from "./../../shared/Icons";
import Locale from "translations";
import moment from "moment";
import { TimeField } from "./customTimeField";
import {setMinutes, setHours} from "date-fns";

export default function ArrivalDeparture({ state, handleInputs }) {
	const { operationStatement } = Locale;
	const data = state?.arrival_departure;
	const today = new Date().toLocaleDateString();


  
	

	return (
		<div className="item-row mt-0">
			<h4 className="header-title">
				<ClockTimeIcon />
				<span className="mx-3">{operationStatement.ArrivalandDeparture}</span>
			</h4>
			<div className='item-content-wrapper'>
				<div className="item-content">
					<div className="header-tabs ">
						<div className="tab">
							<label htmlFor="">{operationStatement.Port}</label>
						</div>
						<div className="tab">
							<label htmlFor="">
								<p>{operationStatement.Arrival}</p>
								<span>({operationStatement.BeforearrivalTrip})</span>
							</label>
						</div>
						<div className="tab">
							<label htmlFor="">
								<p>{operationStatement.Departure}</p>
								<span>({operationStatement.BeforeDepartureTrip})</span>
							</label>
						</div>
					</div>

					<div>
						{/*** Jeddah */}
						<div className="content-tabs">
							<div className="label-data tab">
								<h1>
									<PlaneIcon />
									<p className="d-inline mx-2">
										{operationStatement.JeddahAirport}
									</p>
								</h1>
							</div>
							<div className="tab">
								<div className="time-feild">
									<DateTimePickerField
										hasLabel={false}
										label="Date"
										placeholder="00:00"
										open={false}
									
							
										id="jeddah_airport_arrival"
										name="jeddah_airport_arrival"
										date={
											data?.find(
												(item) => item.city_id === 134 && item.port_type == 2
											)?.arrival
										}
										onChangeDate={(time) =>
											{
												debugger
												handleInputs(time, "arrival_departure", 2, 134, "arrival")
											}
										}
										showTimeSelectOnly={true}
										icon={WathcIconsSrc}
										dateFormat="HH:mm"
										maxDate="09:00"
										onKeyDown={(e) => {
											if (e.keyCode == 32 || e.keyCode == 8) {
												handleInputs(
													moment(`${today} ${"00:00"}`).toDate(),
													"arrival_departure",
													2,
													134,
													"arrival"
												);
											}
										}}
									/>
									{/* <TimeField
										changeValue={(hours, minutes) => {
											const value =
												+hours || +minutes ? `${hours}:${minutes}` : "";
											handleInputs(value, "arrival_departure", 2, 134, "arrival");
										}}
										label={"Duration"}
										value={{
											hours: data
												?.find(
													(item) => item.city_id === 134 && item.port_type == 2
												)
												?.arrival?.split(":")[0],
											minutes: data
												?.find(
													(item) => item.city_id === 134 && item.port_type == 2
												)
												?.arrival?.split(":")[1],
										}}
									/> */}
								</div>
								<span>{operationStatement.Hour}</span>
							</div>
							<div className="tab">
								<div className="time-feild">
									<DateTimePickerField
										hasLabel={false}
										placeholder="00:00"
	open={false}
										id="jeddah_airport_departure"
										name="jeddah_airport_departure"
										date={
											data?.find(
												(item) => item.city_id === 134 && item.port_type == 2
											)?.departure
										}
										onChangeDate={(time) =>
											handleInputs(time, "arrival_departure", 2, 134, "departure")
										}
										showTimeSelectOnly={true}
										icon={WathcIconsSrc}
										dateFormat="HH:mm"
										onKeyDown={(e) => {
											if (e.keyCode == 32 || e.keyCode == 8) {
												handleInputs(
													moment(`${today} ${"00:00"}`).toDate(),
													"arrival_departure",
													2,
													134,
													"departure"
												);
												console.log(state);
											}
										}}
									/>
								</div>
								<span>{operationStatement.Hour}</span>
							</div>
						</div>
						{/**Madinah */}
						<div className="content-tabs">
							<div className="label-data tab">
								<h1>
									<PlaneIcon />
									<p className="d-inline mx-2">
										{operationStatement.MadinahAirport}
									</p>
								</h1>
							</div>
							<div className="tab">
								<div className="time-feild">
									<DateTimePickerField
										hasLabel={false}
										placeholder="00:00"
	open={false}
										showTimeSelectOnly={true}
										icon={WathcIconsSrc}
										name="madina_airport_arrival"
										id="madina_airport_arrival"
										date={
											data?.find(
												(item) => item.city_id === 4 && item.port_type == 2
											)?.arrival
										}
										onChangeDate={(time) =>
											handleInputs(time, "arrival_departure", 2, 4, "arrival")
										}
										dateFormat="HH:mm"
										onKeyDown={(e) => {
											if (e.keyCode == 32 || e.keyCode == 8) {
												handleInputs(
													moment(`${today} ${"00:00"}`).toDate(),
													"arrival_departure",
													2,
													4,
													"arrival"
												);
											}
										}}
									/>
								</div>
								<span>{operationStatement.Hour}</span>
							</div>
							<div className="tab">
								<div className="time-feild">
									<DateTimePickerField
										hasLabel={false}
										placeholder="00:00"
	open={false}
										name="madina_airport_arrival"
										id="madina_airport_arrival"
										date={
											data?.find(
												(item) => item.city_id === 4 && item.port_type == 2
											)?.departure
										}
										onChangeDate={(time) =>
											handleInputs(time, "arrival_departure", 2, 4, "departure")
										}
										showTimeSelectOnly={true}
										icon={WathcIconsSrc}
										dateFormat="HH:mm"
										onKeyDown={(e) => {
											if (e.keyCode == 32 || e.keyCode == 8) {
												handleInputs(
													moment(`${today} ${"00:00"}`).toDate(),
													"arrival_departure",
													2,
													4,
													"departure"
												);
											}
										}}
									/>
								</div>
								<span>{operationStatement.Hour}</span>
							</div>
						</div>
					</div>
					{/*** Yanbu  */}
					<div className="content-tabs">
						<div className="label-data tab">
							<h1>
								<PlaneIcon />
								<p className="d-inline mx-2">{operationStatement.YanbuAirport}</p>
							</h1>
						</div>
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
	open={false}
									name="yanbu_airport_arrival"
									id="yanbu_airport_arrival"
									date={
										data?.find(
											(item) => item.city_id === 224 && item.port_type == 2
										)?.arrival
									}
									onChangeDate={(time) =>
										handleInputs(time, "arrival_departure", 2, 224, "arrival")
									}
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if (e.keyCode == 32 || e.keyCode == 8) {
											handleInputs(
												moment(`${today} ${"00:00"}`).toDate(),
												"arrival_departure",
												2,
												224,
												"arrival"
											);
										}
									}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
	open={false}
									name="yanbu_airport_arrival"
									id="yanbu_airport_arrival"
									date={
										data?.find(
											(item) => item.city_id === 224 && item.port_type == 2
										)?.departure
									}
									onChangeDate={(time) =>
										handleInputs(time, "arrival_departure", 2, 224, "departure")
									}
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if (e.keyCode == 32 || e.keyCode == 8) {
											handleInputs(
												moment(`${today} ${"00:00"}`).toDate(),
												"arrival_departure",
												2,
												224,
												"departure"
											);
										}
									}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
					</div>
					{/*** Jeddah Islamic Port  */}
					<div className="content-tabs">
						<div className="label-data tab">
							<h1>
								<PortIcon />
								<p className="d-inline mx-2">
									{operationStatement.JeddahIslamicPort}{" "}
								</p>
							</h1>
						</div>
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
	open={false}
									name="jeddeh_port_arrival"
									id="jeddeh_port_arrival"
									date={
										data?.find(
											(item) => item.city_id === 134 && item.port_type == 3
										)?.arrival
									}
									onChangeDate={(time) =>
										handleInputs(time, "arrival_departure", 3, 134, "arrival")
									}
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if (e.keyCode == 32 || e.keyCode == 8) {
											handleInputs(
												moment(`${today} ${"00:00"}`).toDate(),
												"arrival_departure",
												3,
												134,
												"arrival"
											);
										}
									}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
	open={false}
									name="jeddeh_port_departure"
									id="jeddeh_port_departure"
									date={
										data?.find(
											(item) => item.city_id === 134 && item.port_type == 3
										)?.departure
									}
									onChangeDate={(time) =>
										handleInputs(time, "arrival_departure", 3, 134, "departure")
									}
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if (e.keyCode == 32 || e.keyCode == 8) {
											handleInputs(
												moment(`${today} ${"00:00"}`).toDate(),
												"arrival_departure",
												3,
												134,
												"departure"
											);
										}
									}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
					</div>

					{/*** Yanbu Port  */}
					<div className="content-tabs">
						<div className="label-data tab">
							<h1>
								<PortIcon />
								<p className="d-inline mx-2">{operationStatement.YanbuPort} </p>
							</h1>
						</div>
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
	open={false}
									name="yanbu_port_arrival"
									id="yanbu_port_arrival"
									date={
										data?.find(
											(item) => item.city_id === 224 && item.port_type == 3
										)?.arrival
									}
									onChangeDate={(time) =>
										handleInputs(time, "arrival_departure", 3, 224, "arrival")
									}
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if (e.keyCode == 32 || e.keyCode == 8) {
											handleInputs(
												moment(`${today} ${"00:00"}`).toDate(),
												"arrival_departure",
												3,
												224,
												"arrival"
											);
										}
									}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
						<div className="tab">
							<div className="time-feild">
								<DateTimePickerField
									hasLabel={false}
									placeholder="00:00"
	open={false}
									name="yanbu_port_departure"
									id="yanbu_port_departure"
									date={
										data?.find(
											(item) => item.city_id === 224 && item.port_type == 3
										)?.departure
									}
									onChangeDate={(time) =>
										handleInputs(time, "arrival_departure", 3, 224, "departure")
									}
									showTimeSelectOnly={true}
									icon={WathcIconsSrc}
									dateFormat="HH:mm"
									onKeyDown={(e) => {
										if (e.keyCode == 32 || e.keyCode == 8) {
											handleInputs(
												moment(`${today} ${"00:00"}`).toDate(),
												"arrival_departure",
												3,
												224,
												"departure"
											);
										}
									}}
								/>
							</div>
							<span>{operationStatement.Hour}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
