import { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import ZayaraForumFooter from "./components/Footer";
import ZayaraForumHeader from "./components/Header";
import { forumGroupsList } from "./static-data";

export default function ZayaraForumFocusGroupPage() {
  const { group_id } = useParams();
  const pageData = forumGroupsList.find(group => group.url === group_id);

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])


  return (
    <main className="zayara-forum inner-page" dir="rtl">
      <ZayaraForumHeader title={pageData.title} />


      <section className="landing-groups-container">
        <div className="container">
          <div className="row mx-0">
            <div className="col-md-8 col-12">
              <h2 className="title group-title">عن مجموعة التركيز</h2>
              <p className="description">{pageData.description}</p>
            </div>
            <div className="col-md-4 col-12">
              <img src={pageData.image} alt={pageData.title} className="img-fluid d-none d-md-block" />
            </div>
          </div>

          <div className="discussion_topics mt-md-5 mt-3">
            <h3 className="title"> محاور النقاش </h3>
            <ul className="d-flex flex-column justify-content-center align-items-center gap-10">
              {pageData.discussion_topics.map(topic => {
                return (
                  <li key={topic} className="rounded-lg px-2 text-center  w-100 border" style={{ maxWidth: "700px", borderColor: "#DDDCDC", paddingBlock: "10px" }}>
                    {topic}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </section>

      <ZayaraForumFooter group={pageData.url} />
    </main>
  )
}
