import axios from "axios";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import validate, { isFormValid } from "helpers/validate";
import useFetchCountries from "hooks/useFetchCountries";
import { useMemo, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useLocation } from "react-router-dom";
// import { uploadFile } from "services/auth";
import Locale from "translations";
import ZayaraForumFooter from "./components/Footer";
import ZayaraForumHeader from "./components/Header";
import PhoneInput from "./components/PhoneInput";
// import UploadImageInput from "./components/UploadImageInput";
import { forumGroupsList } from "./static-data";


const expertObj = {
  name: "",
  company_name: "",
  job_title: "",
  country_id: null,
  phone_code: null,
  phone_number: "",
  email: "",
  image: null
}

export default function ZayaraForumExpertPage() {
  Locale.setLanguage("ar");
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("group");
  const pageData = forumGroupsList.find(group => group.url === param);
  const history = useHistory();

  const [countries] = useFetchCountries();

  const mappedCountries = useMemo(() => {
    return countries.map(country => {
      return {
        ...country,
        name: country?.names?.ar || country?.name
      }
    })
  }, [countries]);

  const [formState, setFormState] = useState({
    ticket_name: param
      ? { id: param, label: pageData.title, name: pageData.title }
      : null,
    sender_name: "",
    sender_email: "",
    sender_phone_code: "",
    sender_phone_number: "",
    experts: [expertObj]
  });

  const [errors, setErrors] = useState({});


  function handleExpertsIputsChanges({ key, value, index }) {
    const formStateClone = { ...formState };
    let expertsClone = [...formStateClone.experts];

    expertsClone[index] = { ...expertsClone[index], [key]: value };

    setErrors({
      ...errors,
      ...validate(
        { name: [key] + "_" + [index], value },
        { required: key !== "image", email: key === "email" }
      ),
    });

    if (key === "country_id" && !expertsClone[index].phone_code) {
      expertsClone[index] = { ...expertsClone[index], [key]: value, phone_code: value?.phone_code }
      setErrors({
        ...errors,
        ...validate(
          { name: "phone_code_" + [index], value: value?.phone_code },
          { required: true }
        ),
        ...validate(
          { name: "country_id_" + [index], value },
          { required: true }
        ),
      });
    }

    setFormState({ ...formState, experts: expertsClone });
  }

  function handleIputsChanges({ key, value }) {
    let formStateClone = { ...formState, [key]: value }
    if (key !== "image") {
      setErrors({
        ...errors,
        ...validate(
          { name: key, value: value },
          { required: true, email: key === "sender_email" }
        ),
      });
    }
    setFormState(formStateClone)
  }



  function addExpert() {
    let formStateClone = { ...formState }
    formStateClone.experts = [...formStateClone.experts, expertObj]
    setFormState(formStateClone)
  }

  function removeExpert(index) {
    let expertsClone = [...formState.experts];
    expertsClone.splice(index, 1);
    setFormState({ ...formState, experts: expertsClone })

  }

  // async function handleUpload(file, index) {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("name", file?.name);
  //   formData.append("bucket", "zayara-forum");
  //   const response = await uploadFile(formData);
  //   if (response.data.data.uuid) {
  //     let expertsClone = [...formState.experts];
  //     expertsClone[index].image = response.data.data.uuid
  //     setFormState({ ...formState, experts: expertsClone })
  //   }
  // }


  function checkFormErrors() {
    let submitError = {}
    formState.experts.forEach((expert, index) => {
      Object.keys(expert).forEach(key => {
        submitError = {
          ...submitError,
          ...validate(
            { name: [key] + "_" + [index], value: expert[key] },
            { required: key !== "image", email: key === "email" }
          ),
        }
      });
    })
    Object.keys(formState).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: formState[key] },
          { required: key !== "image", email: key === "email" }
        ),
      }
    });
    setErrors(() => submitError)

    return submitError;
  }

  async function submitForm(e) {
    e.preventDefault();
    let formHasErrors = !isFormValid(checkFormErrors());
    if (formHasErrors) return;
    const requestData = {
      ...formState,
      experts: formState.experts.map(expert => {
        return {
          ...expert,
          country_id: expert.country_id?.id,
        }
      }),
      ticket_name: formState?.ticket_name?.name,
    }
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/v1/safa-event/tickets/recommend-expert`, requestData).then(res => res);
    if (res.status === 200 || res.status === 201) {
      store.addNotification({
        title: "التسجيل",
        message: "تم التسجيل بنجاح",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      history.push("/umrah-forum")
    }
  }


  return (
    <main className="zayara-forum inner-page form-page" dir="rtl">
      <ZayaraForumHeader title={" ترشيح خبير متخصص"} />

      <section className="landing-groups-container">
        <div className="container form-container">
          <h2 className="form-title mb-3 font-weight-normal" style={{ color: "#242424" }}> ارغب بترشيح خبراء متخصصين</h2>
          <p style={{ color: "#282828" }}>سجل بيانات الخبير المتخصص لحجز مكانه/مكانها في مجموعة التركيز</p>

          <form onSubmit={submitForm}>
            {/* ticket_name */}
            <div className="col-12 my-3">
              <SelectField
                hasLabel={false}
                placeholder={"اختر"}
                label={" مجموعة التركيز *"}
                name="ticket_name"
                id="ticket_name"
                value={formState.ticket_name?.label}
                options={forumGroupsList.map(group => ({ id: group.url, label: group.title, name: group.title }))}
                onChange={(e) => {
                  setFormState({ ...formState, ticket_name: e })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "ticket_name", value: e },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.ticket_name?.required ? "danger" : ""}
                errors={errors?.ticket_name}
              />
            </div>
            <div className="row mx-0">

              {/* sender name */}
              <div className="col-md-4 col-12 my-3 ">
                <TextField
                  label={" اسم القائم بالدعوة *"}
                  placeholder={"اسم القائم بالدعوة"}
                  value={formState.sender_name}
                  name="sender_name"
                  id="sender_name"
                  onChange={(e) => handleIputsChanges({ key: "sender_name", value: e.target.value })}
                  color={errors?.sender_name?.required ? "danger" : ""}
                  errors={errors?.sender_name}
                />
              </div>
              {/* sender email */}
              <div className="col-md-4 col-12 my-3 ">
                <TextField
                  label={"البريد الإلكتروني للقائم بالدعوة *"}
                  placeholder={"اسم القائم بالدعوة"}
                  value={formState.sender_email}
                  name="sender_email"
                  id="sender_email"
                  onChange={(e) => handleIputsChanges({ key: "sender_email", value: e.target.value })}
                  color={errors?.sender_email?.required || errors.sender_email?.email ? "danger" : ""}
                  errors={errors?.sender_email}
                />
              </div>

              {/* sender phone */}
              <div className="col-md-4 col-12 my-3 phone">
                <PhoneInput
                  phoneError={errors?.sender_phone_number}
                  phoneECoderror={errors?.sender_phone_code}
                  onCodeChange={(e) => { handleIputsChanges({ key: "sender_phone_code", value: e?.phone_code }) }}
                  onNumberChange={(e) => handleIputsChanges({ key: "sender_phone_number", value: e.target.value })}
                  phone_code={formState.sender_phone_code}
                  phone_number={formState.sender_phone_number}
                  countries={mappedCountries}
                />
              </div>
            </div>

            {formState.experts.map((expert, index) => {
              return (
                <div className="row mx-0 mb-4 p-4" style={{ backgroundColor: "#FBF9F4", rowGap: "16px" }} key={`expert-${index}`}>
                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-10 col-12 mb-3">
                    <h4>بيانات خبير متخصص {index + 1}</h4>
                    {index > 0 &&
                      <button type="button" className="btn p-0 shadow-none" onClick={() => removeExpert(index)} style={{ color: "#EA5455", fontSize: "16px" }}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="16" height="16" rx="8" fill="#EA5455" />
                          <path d="M5 11L11 5M11 11L5 5" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                        <span className="mx-2">
                          حذف
                        </span>
                      </button>
                    }
                  </div>

                  {/* name */}
                  <div className="col-md-4 col-12">
                    <TextField
                      type="text"
                      label={"الاسم *"}
                      placeholder={"اكتب الاسم هنا"}
                      value={expert.name}
                      name="name"
                      id="name"
                      onChange={(e) => handleExpertsIputsChanges({ key: "name", value: e.target.value, index })}
                      color={errors?.[`name_${index}`]?.required ? "danger" : ""}
                      errors={errors?.[`name_${index}`]}
                    />
                  </div>
                  {/* company_name */}
                  <div className="col-md-4 col-12">
                    <TextField
                      label={"الجهة *"}
                      placeholder={"اكتب اسم الشركة"}
                      value={expert.company_name}
                      name="company_name"
                      id="company_name"
                      onChange={(e) => handleExpertsIputsChanges({ key: "company_name", value: e.target.value, index })}
                      color={errors?.[`company_name_${index}`]?.required ? "danger" : ""}
                      errors={errors?.[`company_name_${index}`]}
                    />
                  </div>

                  {/* job_title */}
                  <div className="col-md-4 col-12">
                    <TextField
                      label={"الوظيفة / المنصب *"}
                      placeholder={"اكتب الوظيفة هنا"}
                      value={expert.job_title}
                      name="job_title"
                      id="job_title"
                      onChange={(e) => handleExpertsIputsChanges({ key: "job_title", value: e.target.value, index })}
                      color={errors?.[`job_title_${index}`]?.required ? "danger" : ""}
                      errors={errors?.[`job_title_${index}`]}
                    />
                  </div>

                  {/* country */}
                  <div className="col-md-4 col-12">
                    <SelectField
                      label={"الدولة *"}
                      placeholder={"اختر"}
                      name="country_id"
                      id="country_id"
                      value={expert.country_id?.name}
                      options={mappedCountries}
                      onChange={(e) => handleExpertsIputsChanges({ key: "country_id", value: e, index })}
                      color={errors?.[`country_id_${index}`]?.required ? "danger" : ""}
                      errors={errors?.[`country_id_${index}`]}
                    />
                  </div>

                  {/* phone */}
                  <div className="col-md-4 col-12 phone">
                    <PhoneInput
                      phoneError={errors?.[`phone_number_${index}`]}
                      phoneECoderror={errors?.[`phone_code_${index}`]}
                      onCodeChange={(e) => handleExpertsIputsChanges({ key: "phone_code", value: e?.phone_code, index })}
                      onNumberChange={(e) => handleExpertsIputsChanges({ key: "phone_number", value: e.target.value, index })}
                      phone_code={expert.phone_code}
                      phone_number={expert.phone_number}
                      countries={mappedCountries}
                    />
                  </div>

                  {/* email */}
                  <div className="col-md-4 col-12">
                    <TextField
                      label={"البريد الالكتروني *"}
                      placeholder={"اكتب البريد الإلكتروني هنا"}
                      value={expert.email}
                      name="email"
                      id="email"
                      onChange={(e) => handleExpertsIputsChanges({ key: "email", value: e.target.value, index })}
                      color={errors?.[`email_${index}`]?.required || errors?.[`email_${index}`]?.email ? "danger" : ""}
                      errors={errors?.[`email_${index}`]}
                    />
                  </div>

                  {/* image */}
                  {/* <div className="col-12">
                    <UploadImageInput
                      imageName={expert.image}
                      onChange={(e) => handleUpload(e.target.files[0], index)}
                    />
                  </div> */}
                </div>

              )
            })}

            <div className="col-12 d-flex flex-wrap justify-content-between mt-4 gap-10">
              <button type="button" className="btn main-btn px-2" onClick={addExpert}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 12H18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12 18V6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span className="mx-1">اضف خبيراً آخر </span>
              </button>

              <button className="btn main-btn form-btn ">تسجيل الآن</button>
            </div>
          </form>
        </div>
      </section>
      <ZayaraForumFooter hideButtons={true} />
    </main>
  )
}
