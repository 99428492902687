import axios from "axios";
import { cleanEmpty } from './general';
import { jsonBeautify } from 'helpers/utils';

const baseURL = process.env.REACT_APP_API_URL;

export const getPageBuilder = async () => {
	return await axios
		.get(`${baseURL}/v1/company-management/get-page-builder-config`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getPageBuilderByCompany = async (name) => {
	return await axios
		.get(`${baseURL}/v1/company-management/public-page/${name}`)
		.then((res) => res)
		.catch((err) => err.response);
};


export const setPageBuilder = async (data) => {
	return await axios
		.post(
			`${baseURL}/v1/company-management/set-page-builder-config`,
			{ "page_builder_config": jsonBeautify(data) }
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const publishPublicPage = async (data) => {
	return await axios
		.post(
			`${baseURL}/v1/company-management/publish-public-page`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

// export const getProductsList = async ({ product_type_id, item_id,no_service_type }) => {
// 	return await axios
// 		.post(`${baseURL}/trip-management/products_list`, {
// 			version: "2",
// 			product_type_id,
// 			item_id,
// 			no_service_type
// 		})
// 		.then((res) => res)
// 		.catch((err) => err.response);
// };
export const getProductsList = async ({ product_type_id, item_id, no_service_type }) => {
	return await axios
		.post(`${baseURL}/trip-management/list-products-for-public`, {
			version: "2",
			product_type_id,
			item_id,
			no_service_type
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const getServiceBuilderList = async ({ type }) => {
	return await axios
		.get(`${baseURL}/v1/builder-services/list-for-public?status=enabled&type=${type}`)
		.then((res) => res)
		.catch((err) => err.response);
};


export const getPackage = async (product_type_id) => {
	return await axios
		.get(`${baseURL}/v1/company-management/packages/${product_type_id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const sendRequestPackage = async (name, data) => {
	return await axios
		.post(`${baseURL}/v1/company-management/public-page/${name}/request-package`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const sendGetInTouch = async (name, data) => {
	return await axios
		.post(`${baseURL}/v1/company-management/public-page/${name}/get-in-touch`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const transalteText = async (data) => {
	return await axios
		.post(`${baseURL}/v1/translate`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateCompanyWhatsAppNumber = async (data) => {
	return await axios
		.post(`${baseURL}/v1/company-management/update-whatsapp-number`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const packagesUpdateStatus = async (data) => {
	return await axios
		.post(`${baseURL}/trip-management/update-website-status`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const servicesUpdateStatus = async (data) => {
	return await axios
		.post(`${baseURL}/v1/builder-services/update-website-status`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchBucket = async (bucketName) => {
	return await axios
		.get(`${baseURL}/v1/files/fetch-bucket/${bucketName}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const deleteFileFromBucket = async (uuid) => {
	return await axios
		.delete(`${baseURL}/v1/files/delete-file/${uuid}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchBuilderRequestsList = (filters) => {
	return axios.get(`${baseURL}/v1/builder-services/requests/list`, { params: cleanEmpty(filters) })
		.then((res) => res)
		.catch((err) => err.response);
}

export const fetchBuilderRequestById = async (id) => {
	return axios.get(`${baseURL}/v1/builder-services/requests/view/${id}`)
		.then((res) => res)
		.catch(err => err.response)
}

export const exportBuilderRequestsExcel = async (filters) => {
	return axios.get(`${baseURL}/v1/builder-services/requests/excel`, {
		params: cleanEmpty(filters),
		responseType: 'blob'
	})
		.then((res) => res)
		.catch(err => err.response)
}

export const addCallLog = async (data) => {
	return axios.post(`${baseURL}/v1/builder-services/requests/add-call-log`, data)
		.then((res) => res)
		.catch(err => err.response)
}

export const serviceBuilderPackagesList = async (params) => {
	return axios.get(`${baseURL}/v1/builder-services/main`, {params})
		.then((res) => res)
		.catch(err => err.response)
}