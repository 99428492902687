import { useWebBuilderDispatch, useWebBuilderState } from 'context/webBuilder';
import HeroHeader from 'modules/WebBuilder-V2/Components/Content/Header'
import React, { useCallback, useState } from 'react'
import { useLocation, useParams, Link } from "react-router-dom";
import UploadFileComponent from 'modules/WebBuilder-V2/shared/UploadFile';
import { ReactComponent as UploadIcon } from "assets/images/webBuilder/upload-hero-img.svg";
import { ReactComponent as ChevronDown } from "assets/images/webBuilder/chevron-down.svg";
import Footer from 'modules/WebBuilder-V2/Components/Content/Footer';
import CustomPageAboutUs from './AboutUs';
import CustomPagePackage from './Package';
import CustomPageHotels from './Hotels';
import CustomPageTours from './Tours';
import Locale from 'translations';
import CustomPageFlights from './Flight';
import CustomPageTransfers from './Transfer';
import CustomPageOthers from './Others';

const bodyElements = {
  packages: { component: CustomPagePackage, acceptTypes: [] },
  hotels: { component: CustomPageHotels, acceptTypes: [] },
  tours: { component: CustomPageTours, acceptTypes: [] },
  flights: { component: CustomPageFlights, acceptTypes: [] },
  transfer: { component: CustomPageTransfers, acceptTypes: [] },
  otherService: { component: CustomPageOthers, acceptTypes: [] },

};

export default function DestinationThemePage() {
  const { webBuilder } = Locale;
  const { pagesData, selectedLanguage, style } = useWebBuilderState();
  const dispatch = useWebBuilderDispatch();
  const location = useLocation();
  const params = useParams();
  const pageId = params.pageName;
  const link = params.name ? `/public-page-v2/${params.name}` : `/web-builder-v2/preview/`;
  const preview = !location.pathname.includes('edit');
  const customPageData = pagesData?.find(page => page.id === pageId);
  const [focusContainer, setFocusContainer] = useState("");
  function handleFocus(value) {
    setFocusContainer(value);
  };


  const updatePageHero = ({ id, value }) => {
    dispatch({
      type: "updatePageHero",
      payload: {
        id,
        value
      },
    });
  };

  const handleUpload = (uuid) => {
    updatePageHero({ id: pageId, value: uuid });
  };

  const renderComponent = useCallback((element, index) => {
    const type = element.type;
    const selectedElement = bodyElements[type];
    const Component = selectedElement?.component;

    return Component && (element?.items?.length > 0 || !preview) ? (
      <Component
        details={{ ...element, index }}
        onFocus={handleFocus}
        focusContainer={focusContainer}
        pageId={pageId}
        title={selectedLanguage === "ar"
          ? webBuilder[element?.type] + " " + customPageData?.mainHeader[selectedLanguage]
          : customPageData?.mainHeader[selectedLanguage] + " " + webBuilder[element?.type]
        }
        preview={preview}
        uuid={customPageData?.uuid}
      />
    ) : null;
  }, [customPageData?.mainHeader, customPageData?.uuid, focusContainer, pageId, preview, selectedLanguage, webBuilder]);


  return (
    <div className={`web-builder-preview web-builder-content web-builder-page-contianer custom-page p-0 ${style?.theme}`}>
      <HeroHeader />
      {/* hero */}
      {customPageData &&
        <>
          <div className="web-builder-hero p-3"
            style={{
              backgroundImage: `linear-gradient(#00000021 50%, #00000021), url(${customPageData?.hero})`,
            }}
          >
            <div className="d-flex flex-column gap-24">
              <h1>
                {customPageData.mainHeader[selectedLanguage]}
              </h1>
              {preview &&
                <div className='page-bread-crumb'>
                  <Link className='home' to={link}>Home</Link>
                  <span> <ChevronDown /></span>
                  <span className='search'>{customPageData?.mainHeader[selectedLanguage]}</span>
                </div>
              }
            </div>

            {!preview ? (
              <div className="update-hero-layout">
                <UploadFileComponent
                  icon={UploadIcon}
                  id="hero-bg"
                  label="Change image"
                  onUpload={handleUpload}
                />
              </div>
            ) : null}
          </div>


          <div className="web-builder-content">
            <CustomPageAboutUs
              details={customPageData?.about}
              focusContainer={focusContainer}
              onFocus={handleFocus}
              pageId={pageId}
              mainHeader={customPageData?.mainHeader}
            />


            <div className="container p-md-0 mt-5">
              {customPageData?.content?.map((element, index) => {
                return renderComponent(element, index)
              })}
            </div>

          </div>
        </>
      }
      <Footer focusContainer={focusContainer} handleFocus={handleFocus} />


    </div>
  )
}