import { useEffect, useState, useRef } from 'react';
import { ReactComponent as WathcIconsSrc } from 'assets/images/umrah-operations/watch-icon.svg';

export const TimeField = ({ changeValue, label, value, errorMsg, onKeyDown }) => {
	const [time, setTime] = useState({
		hours: '00',
		minutes: '00',
	});
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const dropdownRef = useRef(null);
	const hoursRef = useRef(null);
	const minutesRef = useRef(null);

	useEffect(() => {
		if (value && value.hours && value.minutes) {
			setTime({
				hours: value.hours,
				minutes: value.minutes,
			});
		}
	}, [value]);

	const handleTime = ({ key, value }) => {
		setTime((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	const handleIconClick = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const handleDropdownSelect = (hours, minutes) => {
		setTime({ hours, minutes });
		changeValue(hours, minutes);
		// setIsDropdownOpen(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsDropdownOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownRef]);

	// Removed handleHourScroll and handleMinuteScroll

	return (
		<div className={`custom-timing-settings ${errorMsg ? 'field-has-error' : ''}`}>
			<label className={`d-block time-label `}>{label}</label>
			<div className="custom-time-field-container">
				<div className={`custom-time-field ${errorMsg ? 'field-has-error' : ''}`}>
					<div className="time-details">
						<input
							placeholder="00"
							name="hours"
							value={time.hours}
							type="number"
							min={0}
							max={23}
							onChange={(e) => {
								let value = e.target.value.padStart(2, '0');
								if (value.length > 2 && value[0] === '0') {
									value = value.slice(1);
								}
								if (+value > 23) {
									value = '00';
								}
								handleTime({ key: 'hours', value });
								changeValue(value, time.minutes);
							}}
							onKeyDown={onKeyDown}
						/>
						<small>:</small>
						<input
							placeholder="00"
							name="minutes"
							value={time.minutes}
							type="number"
							min={0}
							max={59}
							onChange={(e) => {
								let value = e.target.value.padStart(2, '0');
								if (value.length > 2 && value[0] === '0') {
									value = value.slice(1);
								}
								if (+value > 59) {
									value = '59';
								}
								handleTime({ key: 'minutes', value });
								changeValue(time.hours, value);
							}}
							onKeyDown={onKeyDown}
						/>
					</div>
					<div>
						<WathcIconsSrc onClick={handleIconClick} className="pointer" />
						{isDropdownOpen && (
							<div ref={dropdownRef} className="time-picker-dropdown">
								<div className="d-flex">
									<div ref={hoursRef} className="time-picker-column">
										{Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0')).map((hour) => (
											<div key={hour}
												className={`time-picker-option ${time.hours === hour ? 'selected-option' : ''}`}
												onClick={() => handleDropdownSelect(hour, time.minutes)}
											>
												{hour}
											</div>
										))}
									</div>
									{/* <div className="time-picker-separator">:</div> */}
									<div ref={minutesRef} className="time-picker-column">
										{Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0')).map((minute) => (
											<div key={minute}
												className={`time-picker-option ${time.minutes === minute
													? 'selected-option'
													: ''
													}`}
												onClick={() => handleDropdownSelect(time.hours, minute)}
											>
												{minute}
											</div>
										))}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{errorMsg && (
				<small className={`${errorMsg ? 'has-error' : ''}`}>{errorMsg}</small>
			)}
		</div>
	);
};