import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import { useProductsBuilderState } from "context/productsBuilder";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";
import { isInclusivelyBeforeDay } from "react-dates";
import {  sharePackage } from "services/productbuilder";
import validate, { isFormValid } from "helpers/validate";
import { store } from "react-notifications-component";
// import TextAreaField from "components/Form/TextAreaField/TextAreaField";
import { useHistory, useParams } from "react-router-dom";
// import SelectField from "components/Form/SelectField/SelectField";
// import useFetchCountries from "hooks/useFetchCountries";
// import { uploadFile } from "services/auth";
import { useSBSState } from "context/global";
import { ReactComponent as UpgradeSubscription } from "assets/images/upgrade-subscription.svg";

export default function SharePackageModal({
	fetchProducts,
	shareModelOpen,
	toggleShareModel,
	product_uuid,
	validation_date_from_at,
	validation_date_to_at,
	is_break_down,
	product_type,
}) {
	const { productsBuilder } = Locale;
	const productData = useProductsBuilderState();
	const { share_b2b, subscriptionPlan } = useSBSState();

	// const [countries] = useFetchCountries();
	const history = useHistory();
	const { bundle } = useParams();
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [publishDate, setPublishDate] = useState({
		date_from: "",
		date_to: "",
		booking_type: "manual",
		terms: "",
		documents: "",
		share_type: "online",
		b2c_share: false,
		b2b_share: false,
		public_share: false,
		countries: [],
		companies: [],
		attachment: [],
		photo: null,
	});

	const sharing_on_b2b_subscription =
		!subscriptionPlan?.includes("sharing_on_b2b");
	const sharing_on_b2c_subscription =
		!subscriptionPlan?.includes("sharing_on_b2c");

	// const [companiesOptions, setCompaniesOptions] = useState([]);

	const customPackageType = product_type
		? product_type?.id === 5
		: productData?.product_type?.id === 5;
	const serviceType = product_type
		? product_type?.id === 6
		: productData?.product_type?.id === 6;

	const isBreakDown =
		is_break_down || productData?.product_price?.product_price_type?.id === 0;

	const checkShareFor =
		publishDate["b2c_share"] ||
		publishDate["b2b_share"] ||
		publishDate["public_share"];

	function checkFormErrors() {
		let SubmitError = {};
		let dataKeys = [
			"date_from",
			"date_to",
			"booking_type",
			// "terms",
			// "documents",
			"share_type",
			"share_for",
		];

		if (publishDate.b2b_share)
			dataKeys = [...dataKeys];
		dataKeys.forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{
						name: key,
						value:
							key === "share_for"
								? publishDate.share_type === "offline"
									? "false"
									: checkShareFor
								: publishDate[key],
					},
					{ required: true }
				),
			};
		});

		setErrors(() => SubmitError);
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	}

	// const uploadFilesOnServer = async () => {
	// 	let promises = [];

	// 	// upload photo file
	// 	const photoFormData = new FormData();
	// 	photoFormData.append("file", publishDate.photo);
	// 	photoFormData.append("name", publishDate.photo.name);
	// 	photoFormData.append("bucket", "productsBuilder");
	// 	promises.push(uploadFile(photoFormData).then((res) => res.data.data.uuid));

	// 	// upload attachment files
	// 	publishDate.attachment?.forEach(async (file) => {
	// 		const formData = new FormData();
	// 		formData.append("file", file);
	// 		formData.append("name", file.name);
	// 		formData.append("bucket", "productsBuilder");
	// 		promises.push(uploadFile(formData).then((res) => res.data.data.uuid));
	// 	});
	// 	const uuid = await Promise.all(promises);

	// 	return {
	// 		photoUuid: uuid.shift(),
	// 		attachmentUuid: uuid,
	// 	};
	// };

	async function publishPackage() {
		const uuid = product_uuid ? product_uuid : productData?.product_uuid;
		const for_b2c = publishDate.b2c_share ? 1 : 0;
		const for_b2b = publishDate.b2b_share ? 1 : 0;
		const for_public = publishDate.public_share ? 1 : 0;
		let data = {
			share_from: moment(publishDate.date_from).format("YYYY-MM-DD"),
			share_to: moment(publishDate.date_to).format("YYYY-MM-DD"),
			booking_type: publishDate.booking_type,
			// share_terms: publishDate.terms,
			// share_required_documents: publishDate.documents,
			share_type: publishDate?.share_type,
			for_b2c,
			for_b2b,
			for_public,
			countries: null,
			companies: null,
			attachments: null,
			photo: null,
		};
		if (for_b2b) {
			// const companies = publishDate.companies.some((i) => i.id === "all")
			// 	? []
			// 	: publishDate.companies.map((i) => i.id);
			// const countries =
			// 	companies.length ||
			// 	publishDate.countries.some((i) => i.id === "all") > 0
			// 		? []
			// 		: publishDate.countries.map((i) => i.id);

			// const { attachmentUuid, photoUuid } = await uploadFilesOnServer();

			data = {
				...data,
				// companies,
				// countries,
				// attachments: attachmentUuid,
				// photo: photoUuid,
			};
		}
		let publishPackageRes = await sharePackage(uuid, data);
		if (publishPackageRes?.status >= 200 && publishPackageRes?.status < 300) {
			store.addNotification({
				message: productsBuilder.packagePublishSuccessfully,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			toggleShareModel();
			history.push(`/${bundle}/products-builder`);
			//  if the share modal in product list make new request to get all products
			if (fetchProducts) {
				fetchProducts();
			}
		}
	}

	useEffect(() => {
		if (isFormValid(errors)) {
			publishPackage();
		}
	}, [isErrorLoaded]);

	//
	//
	//
	//
	// 	"validation_date_from_at32",
	// 	moment(validation_date_from_at).diff(moment()) > 0
	// );
	//
	// 	"validation_date_to_at32",
	// 	moment(validation_date_to_at).diff(moment()) > 0
	// );

	//
	// 	"validation_date_from_at32**",
	// 	moment(validation_date_from_at).diff(moment(),"days")
	// );
	//


	// const AllOption = { id: "all", name: "All" };

	return (
		<div>
			<Modal isOpen={shareModelOpen} size="lg">
				<ModalHeader toggle={toggleShareModel}>
					{productsBuilder.SharingForm}
				</ModalHeader>
				<ModalBody>
					<div className="col-10 mx-auto share-package-modal">
						<div className="row">
							{/* share from date */}
							<div className="col-6">
								<DatePickerField
									label={productsBuilder.shareForm}
									placeholder="DD/MM/YYYY"
									date={publishDate.date_from}
									onDateChange={(date) => {
										setPublishDate({ ...publishDate, date_from: date });
										setErrors({
											...errors,
											...validate(
												{ name: "date_from", value: date },
												{ required: true }
											),
										});
									}}
									isOutsideRange={(day) => {
										return (
											!day.isAfter(
												moment(
													validation_date_from_at
														? moment(validation_date_from_at).diff(
																moment(),
																"days"
														  ) >= 0
															? validation_date_from_at
															: moment(validation_date_to_at).diff(
																	moment(),
																	"days"
															  ) >= 0
															? moment().add(-1, "days")
															: null
														: productData.validation_date_from_at
												)
											) ||
											!isInclusivelyBeforeDay(
												day,
												moment(
													validation_date_to_at
														? moment(validation_date_to_at).diff(
																moment(),
																"days"
														  ) >= 0
															? validation_date_to_at
															: null
														: productData.validation_date_to_at
												)
											)
										);
									}}
									errors={errors?.date_from}
									color={errors?.date_from?.required ? "danger" : ""}
								/>
							</div>
							{/* share to data */}
							<div className="col-6">
								<DatePickerField
									label={productsBuilder.shareTo}
									placeholder="DD/MM/YYYY"
									date={publishDate.date_to}
									onDateChange={(date) => {
										setPublishDate({ ...publishDate, date_to: date });
										setErrors({
											...errors,
											...validate(
												{ name: "date_to", value: date },
												{ required: true }
											),
										});
									}}
									isOutsideRange={(day) => {
										return (
											!day.isAfter(
												moment(
													validation_date_from_at
														? moment(validation_date_from_at).diff(
																moment(),
																"days"
														  ) >= 0
															? validation_date_from_at
															: moment(validation_date_to_at).diff(
																	moment(),
																	"days"
															  ) >= 0
															? moment().add(-1, "days")
															: null
														: productData.validation_date_from_at
												)
											) ||
											!isInclusivelyBeforeDay(
												day,
												moment(
													validation_date_to_at
														? moment(validation_date_to_at).diff(moment()) >= 0
															? validation_date_to_at
															: null
														: productData.validation_date_to_at
												)
											)
										);
									}}
									errors={errors?.date_to}
									color={errors?.date_to?.required ? "danger" : ""}
								/>
							</div>
							{/* booking type */}
							<div className="col-12 mt-3">
								<p>{productsBuilder.reservationType}</p>
								<div className="col-12 row align-items-center mt-1">
									{/* manual reservation */}
									<div className="col-md-4 col-6 d-flex align-items-center">
										<input
											class="mx-2 radio-input"
											type="radio"
											name="manual"
											id="manual"
											value="manual"
											checked={publishDate.booking_type === "manual"}
											onChange={(e) => {
												setPublishDate({
													...publishDate,
													booking_type: e.target.value,
												});
											}}
										/>
										<label className="form-check-label" for="manual">
											{productsBuilder.manualReservation}
										</label>
									</div>
									{/* auto reservation  */}
									<div className="col-md-4 col-6 d-flex align-items-center">
										<input
											class="mx-2 radio-input"
											type="radio"
											name="auto"
											id="auto"
											value="auto"
											checked={publishDate.booking_type === "auto"}
											onChange={(e) => {
												setPublishDate({
													...publishDate,
													booking_type: e.target.value,
												});
											}}
										/>
										<label className="form-check-label" for="auto">
											{productsBuilder.autoReservation}
										</label>
									</div>
								</div>
							</div>
							{/* sharing type */}

							<div className="col-12 mt-3">
								<p>{productsBuilder.sharingType}</p>
								<div className="col-12 row align-items-center mt-1">
									{/* Shared Online */}
									<div className="col-md-4 col-6 d-flex align-items-center">
										<input
											class="mx-2 radio-input"
											type="radio"
											name="online"
											id="online"
											value="online"
											checked={publishDate.share_type === "online"}
											onChange={(e) => {
												setPublishDate({
													...publishDate,
													share_type: e.target.value,
												});
											}}
										/>
										<label className="form-check-label" for="online">
											{productsBuilder.shareMarketplace}
										</label>
									</div>
									{/* Shared Offline */}
									<div className="col-md-4 col-6 d-flex align-items-center">
										<input
											class="mx-2 radio-input"
											type="radio"
											name="offline"
											id="offline"
											value="offline"
											checked={publishDate.share_type === "offline"}
											onChange={(e) => {
												setPublishDate({
													...publishDate,
													share_type: e.target.value,
												});
											}}
										/>
										<label className="form-check-label" for="offline">
											{productsBuilder.shareReservationTeam}
										</label>
									</div>
								</div>
							</div>

							{/* sharing for */}

							{publishDate.share_type !== "offline" ? (
								<div className="col-12 mt-3">
									<p>
										{productsBuilder.sharingFor}{" "}
										{errors?.share_for?.required && !checkShareFor ? (
											<small className="mx-2 text-danger">
												you should select share for
											</small>
										) : null}
									</p>
									<div className="col-12 row align-items-start mt-1">
										{/* Shared B2C */}

										<div
											// className="col-md-4 col-6 d-flex align-items-start"
											className={`col-md-4 col-6 d-flex align-items-start ${
												sharing_on_b2c_subscription ? "shared-disabled" : ""
											}`}
										>
											<input
												class="mx-2 radio-input"
												type="checkbox"
												name="B2C"
												id="B2C"
												checked={publishDate.b2c_share}
												onChange={(e) => {
													setPublishDate({
														...publishDate,
														b2c_share: e.target.checked,
													});
												}}
												disabled={
													serviceType ||
													customPackageType ||
													sharing_on_b2c_subscription
												}
											/>
											<label className="form-check-label" for="B2C">
												B2C
											</label>
											{sharing_on_b2c_subscription ? (
												<div className="upgrade">
													<UpgradeSubscription />
													<span>Upgrade</span>
												</div>
											) : null}
										</div>
										{/* Shared B2B */}
										{share_b2b != "0" ? (
											<div 
											className={`col-md-6 col-6 d-flex flex-column align-items-start ${
												sharing_on_b2b_subscription ? "shared-disabled" : ""
											}`}
											>
												<div className="d-flex">
													<input
														class="mx-2 radio-input"
														type="checkbox"
														name="B2B"
														id="B2B"
														checked={publishDate.b2b_share}
														onChange={(e) => {
															setPublishDate({
																...publishDate,
																b2b_share: e.target.checked,
															});
														}}
														disabled={!isBreakDown || sharing_on_b2b_subscription}
													/>
													<label className="form-check-label" for="B2B">
														Share on Safa-Live Market-place
													</label>
													{sharing_on_b2b_subscription ? (
												<div className="upgrade">
													<UpgradeSubscription />
													<span>Upgrade</span>
												</div>
											) : null}
												</div>
												{!isBreakDown ? (
													<small className="text-warning">
														Please note that you need to price your package in a
														breakdown way.
													</small>
												) : null}
											</div>
										) : null}
									</div>
								</div>
							) : null}

							{/* share for B2B */}

							{/* terms and conditions */}
							{/* <div className="col-12 mt-3">
								<TextAreaField
									name="terms"
									id="terms"
									label={productsBuilder.termsAndConditions}
									placeholder={productsBuilder.termsAndConditions}
									rows="5"
									className="textarea"
									onChange={(e) => {
										setPublishDate({ ...publishDate, terms: e.target.value });
										// setErrors({
										// 	...errors,
										// 	...validate(
										// 		{ name: "terms", value: e.target.value },
										// 		{ required: true }
										// 	),
										// });
									}}
									height={"50px"}
									errors={errors?.terms}
									color={errors?.terms?.required ? "danger" : ""}
								/>
							</div> */}
							{/* Required Documents */}
							{/* <div className="col-12 mt-3">
								<TextAreaField
									name="documents"
									id="documents"
									label={productsBuilder.requiredDocuments}
									placeholder={productsBuilder.requiredDocuments}
									rows="5"
									className="textarea"
									onChange={(e) => {
										setPublishDate({
											...publishDate,
											documents: e.target.value,
										});
										// setErrors({
										// 	...errors,
										// 	...validate(
										// 		{ name: "documents", value: e.target.value },
										// 		{ required: true }
										// 	),
										// });
									}}
									height={"50px"}
									errors={errors?.documents}
									color={errors?.documents?.required ? "danger" : ""}
								/>
							</div> */}
							{/* share button */}
							<div className="col-12 mt-3" onClick={checkFormErrors}>
								<button className="btn">{productsBuilder.share}</button>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}

//////////////////// share uplaod file ///////////////////////
export const UploadFiles = ({ setPublishDate, publishDate, setErrors, errors }) => {
	const { productsBuilder, payment } = Locale;

	const allowedFileExt = [
		"image/jpg",
		"image/jpeg",
		"image/png",
		"application/x-rar-compressed",
		"application/x-zip-compressed",
	];
	const maxFileSizeMB = 2 * 1000 * 1000; // 2 megabyte
	let maxFilesCount = 5;

	function handleFileUpload(e) {
		let files = e.target.files;
		files = Object.keys(files).map((key) => files[key]);
		const remainingFilesCount = maxFilesCount - publishDate?.attachment?.length;
		files =
			files.length > remainingFilesCount
				? files.slice(0, remainingFilesCount)
				: files;
		let acceptedFiles = [];
		// check if there uploaded files less than max files count 5
		if (remainingFilesCount > 0 && remainingFilesCount <= maxFilesCount) {
			files.forEach((file) => {
				if (file.size < maxFileSizeMB && allowedFileExt.includes(file.type)) {
					acceptedFiles.push(file);
				}
			});
			acceptedFiles = [...publishDate?.attachment, ...acceptedFiles];
			setPublishDate({ ...publishDate, attachment: acceptedFiles });
			setErrors({
				...errors,
				...validate(
					{ name: "attachment", value: acceptedFiles.length > 0 },
					{ required: true }
				),
			});
		}
	}

	function handleRemoveAttachment(index) {
		let attachmentClone = [...publishDate?.attachment];
		attachmentClone.splice(index, 1);
		setPublishDate({ ...publishDate, attachment: attachmentClone });
		setErrors({
			...errors,
			...validate(
				{ name: "attachment", value: attachmentClone.length > 0 },
				{ required: true }
			),
		});
	}

	function handleFilePhoto(e) {
		let files = e.target.files[0];
		setPublishDate({ ...publishDate, photo: files });
		setErrors({
			...errors,
			...validate({ name: "photo", value: true }, { required: true }),
		});
	}

	const handleRemovePhoto = () => {
		setPublishDate({ ...publishDate, photo: null });
		setErrors({
			...errors,
			...validate({ name: "photo", value: null }, { required: true }),
		});
	};

	return (
		<>
			<div className="col-6 mt-3">
				<div className="file-upload-control">
					<label htmlFor="" className="m-0">
						{payment.messages.upload}
					</label>
					<i className="fas fa-upload mx-2"></i>
					<input
						type="file"
						accept={allowedFileExt.join(",")}
						onChange={handleFileUpload}
						multiple
						disabled={publishDate?.attachment?.length >= maxFilesCount}
						className={`file-upload-input ${
							publishDate?.attachment?.length >= maxFilesCount ? "disabled" : ""
						}`}
					/>
				</div>
				{errors?.attachment?.required ? (
					<small className="text-danger">attachment is required</small>
				) : null}
				<ul className="cert-files-list">
					{publishDate?.attachment?.map((certFile, index) => {
						return (
							<li className="cert-file text-lowercase" key={index}>
								<i className="fas fa-paperclip file-attach"></i>
								<span className="mx-2">{certFile?.name}</span>
								<i
									className="fas fa-times-circle pointer remove-file"
									onClick={() => handleRemoveAttachment(index)}
								></i>
							</li>
						);
					})}
				</ul>
			</div>

			<div className="col-6 mt-3">
				<div className="file-upload-control bg-blue">
					<label htmlFor="" className="m-0">
						{productsBuilder.uploadAdPhoto}
					</label>
					<i className="fas fa-upload mx-2"></i>
					<input
						type="file"
						accept={"image/jpg,image/jpeg,image/png"}
						onChange={handleFilePhoto}
						className="file-upload-input"
					/>
				</div>
				{errors?.photo?.required ? (
					<small className="text-danger">photo is required</small>
				) : null}
				{publishDate.photo && (
					<ul className="cert-files-list">
						<li className="cert-file text-lowercase">
							<i className="fas fa-paperclip file-attach"></i>
							<span className="mx-2">{publishDate.photo?.name}</span>
							<i
								className="fas fa-times-circle pointer remove-file"
								onClick={handleRemovePhoto}
							></i>
						</li>
					</ul>
				)}
			</div>
		</>
	);
};
