import SelectField from 'components/Form/SelectField/SelectField'
import TextField from 'components/Form/TextField/TextField'
import React from 'react'

export default function PhoneInput({ phoneError, phoneECoderror, onCodeChange, onNumberChange, phone_code, phone_number, countries}) {
  return (
    <div className="w-100">
      {/* phone code */}
      <div className="col-3 px-0 phone_code">
        <SelectField
          hasLabel={false}
          placeholder="+966"
          label=""
          id={`phone_code`}
          name={`phone_code`}
          options={countries}
          value={phone_code}
          onChange={onCodeChange}
          color={phoneECoderror?.required  ? "danger" : ""}
          errors={phoneECoderror}
        />
      </div>

      {/* phone */}
      <div className="col-12 phone_number px-0">
        <TextField
          type={"number"}
          label="رقم الهاتف *"
          id="phone_number"
          name="phone_number"
          removeArrow={true}
          placeholder={"50000000"}
          value={phone_number}
          onChange={onNumberChange}
          color={phoneError?.required  ? "danger" : ""}
          errors={phoneError}
        />
      </div>
    </div>

  )
}
