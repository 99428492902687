import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import OperationStatementHeader from "./StatementHeader";
import Hotels from "./OperationElements/Hotels";
import OperationDetails from "./OperationElements/OperationDetails";
import OperationTabs from "modules/UmrahOperations/shared/Tabs";
import { ReactComponent as OperatingDetailsIcon } from "assets/images/umrah-operations/details.svg";
import { ReactComponent as OperatingHotelsIcon } from "assets/images/umrah-operations/hotels.svg";
import { ReactComponent as OperatingArrivalDepartureIcon } from "assets/images/umrah-operations/arrival-departure.svg";
// import { ReactComponent as OperatingAttractionIcon } from "assets/images/umrah-operations/attraction-icon.svg";
import { ReactComponent as OperatingTransportationIcon } from "assets/images/umrah-operations/car-icon.svg";
import { ReactComponent as OperatingVisaIcon } from "assets/images/umrah-operations/visa-icon.svg";
import { ReactComponent as OperatingOtherIcon } from "assets/images/umrah-operations/other-services-icon.svg";
import { ReactComponent as OperatingCateringIcon } from "assets/images/umrah-operations/catering.svg";
import { ReactComponent as RejectIcon } from "assets/images/umrah-operations/stop-slash-icon.svg";
import { ReactComponent as AcceptIcon } from "assets/images/umrah-operations/check.svg";
import Locale from "translations";
import BreadCrump from "modules/UmrahOperations/shared/BreadCrump";
import ArrivalDeparture from "./OperationElements/ArrivalDeparture";
import {

	changeStatementStatus,
	getOperationStatement,
	getSentOperationStatement,
} from "services/operationStatement";
import { useParams } from "react-router-dom";
import { formatViewOperationStatement } from "modules/UmrahOperations/helper/formatUmrahOperations";
import OperationStatementVisa from "./OperationElements/Visa";
// import OperationStatementAttractions from "./OperationElements/Attractions";
import OperationStatementOtherServices from "./OperationElements/OtherServices";
import OperationStatementTransportation from "./OperationElements/Transportation";
import OperationStatementCatering from "./OperationElements/Catering";
import moment from "moment";
import { now } from "moment/moment";
import { store } from "react-notifications-component";
import { useSBSState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { FetchProductLookupd } from 'services/productbuilder';
import { PassportIcon } from 'modules/UmrahOperations/shared/Icons';
import OperationStatementPassports from './OperationElements/Passports';
import useOperationForms from './OperationElements/useOperationForms';
import { isEqual } from "lodash"
// import ShowSubscription from 'helpers/ShowSubscription';

export default function OperationStatementView() {
	const { operationStatement, companyDetails, inventory, interests } = Locale;
	let { id, reference, bundle } = useParams();
	// TODO replace id with reference in routes
	let statementId = reference;
	let statementReference = id;
	const { locale } = useSBSState();

	const operationDataCache = useRef(null);


	const [productsOption, setProductsOption] = useState([]);

	const [OperationStatement, setOperationStatement] = useState({
		operation_details: {
			adults: 1,
			children: 0,
			infants: 0,
			agent_id: null,
			without_movements: false,
		},
		arrival_departure: {},
		hotels: [],

		visas: [],
		trip: {
			departures: [
				{
					departure_carrier_name: { name: "" },
					departure_trip_number: null,
					departure_date: null,
					departure_port_id: null,
					departure_hall_id: null,
					bus_arrival_date: null,
				},
			],
		},
		attractions: [
			{
				city_id: null,
				attractions_ids: [],
				date_time: null,
				cityAttractionsLookup: [],
			}
		],
		other_services: [],
		transportation: {
			cycle_type: null,
			request_type: null,
			transporter_id: null,
			credential_number: "",
			operation_id: "",
			vehicles: [],
			movements: [],
		},
		catering: [],
		travelers: []
	});

	const [activeTab, setActiveTab] = useState("operation-details");


	const statementType = OperationStatement.operation_details?.statement_type
	const {
		submitOperationDetailsForm,
		submitOperationTripForm,
		submitOperationHotelForm,
		submitOperationCatringForm,
		submitOperationTransportationForm,
		submitOperationVisaForm,
		submitOperationOtherServiceForm,
		errors,
		setErrors
	} = useOperationForms();

	// sentStatementMain
	async function getStatementMain() {
		let statementResponse;
		if (statementReference === "sent") {
			let data = { "is_sent_to_company": false }
			statementResponse = await getSentOperationStatement(statementId, data);
		} else {
			statementResponse = await getOperationStatement(statementId);
		}
		if (statementResponse.status === 200 || statementResponse.status === 201) {
			let data = await formatViewOperationStatement(statementResponse.data.data, Locale);
			operationDataCache.current = data;
			setOperationStatement(data);
		}
	}


	useEffect(() => {
		if (statementId) {
			getStatementMain();
		}
	}, [statementId]);


	const operationTabsItems = useMemo(() => {
		return [
			{
				title: operationStatement.details,
				icon: <OperatingDetailsIcon />,
				url: "operation-details",
				formSubmition: () => submitOperationDetailsForm({ operation_details: OperationStatement?.operation_details })
			},

			{
				title: operationStatement.ArrivalDeparture,
				icon: <OperatingArrivalDepartureIcon />,
				url: "operation-ArrivalDeparture",
				disabled: statementId && statementType?.availableTabs?.includes("operation-ArrivalDeparture") ? false : true,
				formSubmition: () => submitOperationTripForm({ arrival_departure: OperationStatement?.trip })
			},
			{
				title: operationStatement.hotel,
				icon: <OperatingHotelsIcon />,
				url: "operation-hotels",
				disabled:
					statementId &&
						statementType?.availableTabs?.includes("operation-hotels") ? false : true,
				formSubmition: () => submitOperationHotelForm({ cities: OperationStatement?.hotels })
			},
			{
				title: operationStatement.catering,
				icon: <OperatingCateringIcon />,
				url: "operation-catering",
				disabled: statementId &&
					OperationStatement.hotels.length > 0 &&
					statementType?.availableTabs?.includes("operation-catering") ? false : true,
				formSubmition: () => submitOperationCatringForm({ OperationStatement })
			},
			{
				title: operationStatement.transportation,
				icon: <OperatingTransportationIcon />,
				url: "operation-transportation",
				disabled: statementId &&
					!OperationStatement?.operation_details?.without_movements &&
					statementType?.availableTabs?.includes("operation-transportation") ? false : true,
				formSubmition: () => submitOperationTransportationForm({
					transportationData: OperationStatement?.transportation,
					attractionsData: OperationStatement?.attractions
				})
			},
			{
				title: operationStatement.visa,
				icon: <OperatingVisaIcon />,
				url: "operation-visa",
				disabled: statementId && statementType?.availableTabs?.includes("operation-visa") ? false : true,
				formSubmition: () => submitOperationVisaForm({ OperationStatement })
			},
			{
				title: operationStatement.otherServices,
				icon: <OperatingOtherIcon />,
				url: "operation-other",
				disabled: statementId && statementType?.availableTabs?.includes("operation-other") ? false : true,
				formSubmition: () => submitOperationOtherServiceForm({ OperationStatement })
			},
			{
				title: operationStatement.passport,
				icon: <PassportIcon />,
				url: "operation-passports",
				disabled: statementId && statementType?.availableTabs?.includes("operation-passports") ? false : true,
			},
		]
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [OperationStatement, statementId, statementType?.availableTabs]);

	let currentTabIndex = operationTabsItems.findIndex(
		(tab) => tab?.url === activeTab
	);

	// get last available tab 
	const lastAvailableTabIndex = useMemo(() => {
		for (let i = operationTabsItems.length - 1; i >= 0; i--) {
			const tab = operationTabsItems[i];
			if (!tab.disabled && tab.url !== "operation-passports") {
				return i;
			}
		}
		return null;
	}, [operationTabsItems]);


	function handleNextPrev(direction) {
		let nextPrevAvailableIndex = direction === "next" ? currentTabIndex + 1 : currentTabIndex - 1;
		while (operationTabsItems[nextPrevAvailableIndex]?.disabled) {
			nextPrevAvailableIndex = direction === "next" ? nextPrevAvailableIndex + 1 : nextPrevAvailableIndex - 1;
			if (nextPrevAvailableIndex < 0 || nextPrevAvailableIndex >= operationTabsItems.length) {
				return;
			}
		}
		onTabChange(operationTabsItems[nextPrevAvailableIndex]?.url)
	}


	// create function to trigger when tab changes
	// this function will work on click on tab and (next-prev) buttons
	const onTabChange = useCallback(async (nextActiveTab) => {
		// check if current active tab touched
		if (isEqual(OperationStatement, operationDataCache.current) && nextActiveTab !== activeTab) {
			setActiveTab(nextActiveTab);
			return;
		}
		// submit current index tab form this form will return promise 
		const currentFormSubmition = operationTabsItems[currentTabIndex].formSubmition;
		// if the submition is ok switch to target tab
		const response = await currentFormSubmition();

		if (response?.status >= 200 && response?.status < 300) {
			// update cache
			operationDataCache.current = {...OperationStatement};
			// change current tab
			setActiveTab(nextActiveTab);
			// reset errors state
			setErrors({});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [OperationStatement, currentTabIndex]);


	async function changeStatus(status) {
		const res = await changeStatementStatus(statementId, { status });
		if (res?.status === 200) {
			store.addNotification({
				title: `${interests.messages.updateSuccessfully}`,
				message: `${interests.messages.EntriesUpdateSuccess}`,
				insert: "top",
				type: "success",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			getStatementMain();
		}
	}

	useEffect(() => {
		const fetchLookups = async () => {
			const res = await FetchProductLookupd();
			const formatLookup = res?.data?.data?.map((lookup) => {
				return { ...lookup, label: lookup?.name, value: lookup?.id };
			});
			setProductsOption([...formatLookup]);
		};
		fetchLookups();
	}, []);

	const operationHasSent = statementReference ? true : false;
	const isSafaOffline = bundle === "safa_offline";

	return (
		<div className="operation-statement-view px-new-26 px-3">
			<BreadCrump title={operationStatement.operatingStatement} />
			<div className="container-fluid">
				<OperationStatementHeader OperationStatement={OperationStatement} />

				<div className="operating-view-container my-4 px-new-26">
					<div className="align-items-center d-flex flex-wrap gap-1 justify-content-around justify-content-md-between my-3 w-100">
						<p className="operating-title-view">
							{!statementId
								? operationStatement.AddNewOperatingStatement
								: operationStatement.updateNewOperatingStatement}
						</p>

						<div className="d-flex align-content-center">
							<button className={`operating-btn-back mx-1 ${currentTabIndex === 0 || !statementId ? "disabled" : ""}`}
								disabled={currentTabIndex === 0 || !statementId}
								onClick={() => handleNextPrev("prev")}
							>
								{locale === "en" ? (
									<i class="fas fa-arrow-left mx-2"></i>
								) : (
									<i class="fas fa-arrow-right mx-2"></i>
								)}

								{companyDetails.back}
							</button>

							<button className={`operating-btn-next mx-1 ${currentTabIndex === operationTabsItems.length - 1 || !statementId ? "disabled" : ""}`}
								onClick={() => handleNextPrev("next")}
								disabled={currentTabIndex === operationTabsItems.length - 1 || !statementId}
							>
								{companyDetails.next}
								{locale === "en" ? (
									<i class="fas fa-arrow-right mx-2"></i>
								) : (
									<i class="fas fa-arrow-left mx-2"></i>
								)}
							</button>
						</div>
					</div>
					<OperationTabs
						tabs={operationTabsItems}
						activeTab={activeTab}
						IsNotPushUrl={true}
						setActiveTab={onTabChange}
						classNames=" col-md-12 col-12  mx-auto"
					/>

					<div className="col-md-12 mt-4">
						{/* operation details */}
						{activeTab === "operation-details" ? (
							<OperationDetails
								operationHasSent={operationHasSent}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								formSubmition={() => onTabChange(activeTab)}
								errors={errors}
								setErrors={setErrors}
							/>
						) : null}

						{/* Arrival Departure */}
						{activeTab === "operation-ArrivalDeparture" ? (
							<ArrivalDeparture
								operationHasSent={operationHasSent}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								productsOption={productsOption}
								formSubmition={() => onTabChange(activeTab)}
								errors={errors}
								setErrors={setErrors}
							/>
						) : null}

						{activeTab === "operation-hotels" ? (
							<Hotels
								getStatementMain={getStatementMain}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								productsOption={productsOption}
								operationHasSent={operationHasSent}
								formSubmition={() => onTabChange(activeTab)}
								errors={errors}
								setErrors={setErrors}
							/>
						) : null}

						{/* Catering */}
						{activeTab === "operation-catering" ? (
							<OperationStatementCatering
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								operationHasSent={operationHasSent}
								formSubmition={() => onTabChange(activeTab)}
								errors={errors}
								setErrors={setErrors}
							/>
						) : null}

						{/* attractions */}
						{/* {activeTab === "operation-attraction" ? (
							<OperationStatementAttractions
								operationHasSent={operationHasSent}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
							/>
						) : null} */}
						{/* Transportation */}
						{activeTab === "operation-transportation" ? (
							<OperationStatementTransportation
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								operationHasSent={operationHasSent}
								formSubmition={() => onTabChange(activeTab)}
								errors={errors}
								setErrors={setErrors}
							/>
						) : null}
						{/* visa */}
						{activeTab === "operation-visa" ? (
							<OperationStatementVisa
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								operationHasSent={operationHasSent}
								formSubmition={() => onTabChange(activeTab)}
								errors={errors}
								setErrors={setErrors}
							/>
						) : null}
						{/* other services */}
						{activeTab === "operation-other" ? (
							<OperationStatementOtherServices
								operationHasSent={operationHasSent}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								formSubmition={() => onTabChange(activeTab)}
								errors={errors}
								setErrors={setErrors}
							/>
						) : null}
						{/* passport */}
						{activeTab === "operation-passports" ? (
							<OperationStatementPassports
								operationHasSent={operationHasSent}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
							/>
						) : null}

					</div>
				</div>
				<div className="w-100 p-3"></div>

				{(OperationStatement?.status === "new" && moment(now()).isBefore(moment(OperationStatement?.trip?.arrival_date)) && currentTabIndex === lastAvailableTabIndex) ||
					(OperationStatement?.status === "new" && !OperationStatement?.trip?.arrival_date) ||
					(OperationStatement?.status === "draft" && moment(now()).isBefore(moment(OperationStatement?.trip?.arrival_date)) && currentTabIndex === lastAvailableTabIndex && !isSafaOffline) ||
					(OperationStatement?.status === "draft" && !isSafaOffline && !OperationStatement?.trip?.arrival_date && currentTabIndex === lastAvailableTabIndex) ? (
					<ShowForPermission permission="Manage-operation-statement">
						<div className="d-flex justify-content-end py-3">
							{!operationHasSent ?
								<>
									<button
										className=" mx-2 reject-btn"
										onClick={() => changeStatus("rejected")}
									>
										<RejectIcon />
										<span className="mx-2">{inventory.messages.reject}</span>
									</button>

									<button
										className=" accept-btn"
										onClick={() => changeStatus("approved")}
									>
										<AcceptIcon />
										<span className="mx-2">{inventory.messages.accept}</span>
									</button>
								</>
								: null}
						</div>
					</ShowForPermission>
				) : null}
			</div>
		</div>
	);
}
