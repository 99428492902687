import { AddElement } from "modules/WebBuilder-V2/shared/AddElement";
import AddService from "modules/WebBuilder-V2/shared/AddService";
import React, { useState } from "react";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import { useWebBuilderDispatch } from "context/webBuilder";
import { EditBox } from "modules/WebBuilder-V2/shared/EditBox";
import CustomCarousel from "modules/WebBuilder-V2/shared/CustomCarousel";
import DragAndDropContainer from "modules/WebBuilder-V2/shared/DragAndDropContainer";
import { SORT_ITEMS_IN_CONTAINER_ACTION } from "modules/WebBuilder-V2/interfaces/blockTypes";
import OtherCardDefault from "modules/WebBuilder-V2/Components/Content/OtherServices/OtherCardDefault";

const othersBlocksType = "othersBlocksType";

export default function CustomPageOthers({
	details,
	pageId,
	focusContainer,
	onFocus,
	title,
	preview,
	uuid,
}) {
	const { id, type, items, index } = details;

	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);

	const dispatch = useWebBuilderDispatch();

	function handleOpenAddModal() {
		setOpenAdd((prev) => !prev);
	}
	function openEditModal(item) {
		handleOpenAddModal();
		setUpdateItem(item);
	}

	function editItem(item, index) {
		return [
			<span
				className="pointer-event"
				key={`${item.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
			>
				<SettingIcon />
			</span>,
		];
	}

	function deleteBodyContent(itemId) {
		dispatch({
			type: "deleteItemFromCustomPage",
			payload: {
				sectionIndex: index,
				itemId,
				pageId,
			},
		});
	}
	function itemsBoxActions(itemId) {
		return [
			<span
				className="pointer-event"
				key={itemId}
				onClick={() => deleteBodyContent(itemId)}
			>
				<TrashIcon />
			</span>,
		];
	}

	function addItemToOthers(item) {
		updateItem
			? dispatch({
					type: "editItemToCustomPage",
					payload: {
						sectionIndex: index,
						item,
						itemIndex: updateItem.index,
						pageId,
						pageUUID: uuid,
					},
			  })
			: dispatch({
					type: "addItemToCustomPage",
					payload: {
						sectionIndex: index,
						item,
						pageId,
						pageUUID: uuid,
					},
			  });
		setUpdateItem(null);
	}

	function sortOthersItems({ dragIndex, hoverIndex }) {
		// dispatch({
		//   type: "sortItemsInContainer",
		//   payload: {
		//     sectionId: id,
		//     dragIndex,
		//     hoverIndex,
		//   },
		// });
	}

	const selectedIds = items ? items.map((i) => i.id) : [];

	return (
		<>
			{(items?.length > 0 || !preview) && (
				<h3 className="section-title">{title}</h3>
			)}
			<EditBox editMode={focusContainer === type} defaultIcon={""}>
				<div
					id={id}
					className="web-builder-content-other"
					onClick={() => onFocus(type)}
				>
					{!preview && (
						<div className="service-top-section">
							<AddElement title="Add Others" onClick={handleOpenAddModal} />
						</div>
					)}

					<CustomCarousel desktopItemNumber={2}>
						{items.map((item, index) => (
							<DragAndDropContainer
								accept={othersBlocksType}
								type={othersBlocksType}
								id={item.id}
								index={index}
								action={SORT_ITEMS_IN_CONTAINER_ACTION}
								moveItemInContainer={(dragIndex, hoverIndex) =>
									sortOthersItems({ dragIndex, hoverIndex })
								}
								// onDrop={}
								key={item.id}
							>
								<EditBox
									editMode={focusContainer === type}
									actions={[
										...editItem(item, index),
										...itemsBoxActions(item.id),
									]}
								>
									<OtherCardDefault
										{...item}
										openEditModal={openEditModal}
									/>
								</EditBox>
							</DragAndDropContainer>
						))}
					</CustomCarousel>

					{openAdd && (
						<AddService
							handleModalState={handleOpenAddModal}
							open={openAdd}
							item_id={8}
							label={"Service"}
							header={"Others"}
							onAdd={addItemToOthers}
							details={updateItem}
							selectedIds={selectedIds}
						/>
					)}
				</div>
			</EditBox>
		</>
	);
}
