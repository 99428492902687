import validate from "helpers/validate";

export function checkFormKeysErrors({ keys = [], values, setErrors }) {
  let submitErrors = {};

  keys?.forEach(key => {
    submitErrors = {
      ...submitErrors,
      ...validate(
        { name: key, value: values[key] },
        { required: true }
      ),
    }
  });

  setErrors && setErrors({ ...submitErrors });
  return submitErrors;
}
// check arrival departure tab errors
export function checkArrivalDepartureErrors({ arrival_departure, setErrors }) {
  const arrivalRequiredKeys = ["transport_type", "arrival_carrier_name", "arrival_date", "arrival_port_id"];
  const departureRequiredKeys = ["departure_carrier_name", "departure_date", "departure_port_id"];
  let submitError = {};
  // arrival errors
  arrivalRequiredKeys.forEach((key) => {
    submitError = {
      ...submitError,
      ...validate(
        { name: key, value: arrival_departure?.[key]?.id || arrival_departure?.[key] },
        { required: true, date: key === "arrival_date", }
      ),
    };
  });
  // departure errors
  arrival_departure?.departures?.forEach((departure, departureIndex) => {
    departureRequiredKeys.forEach((key) => {
      submitError = {
        ...submitError,
        ...validate(
          { name: `${key}-${departureIndex}`, value: departure[key] },
          { required: true, date: key === "departure_date" }
        ),
      };
    });
  });
  debugger
  setErrors({ ...submitError });
  return submitError;
}

// check hotel tab errors
export function checkCitiesErrors({ cities, setErrors }) {
  let submitErrors = {};
  if (!cities || cities.length === 0) return submitErrors;

  cities?.forEach((city, index) => {
    submitErrors = {
      ...submitErrors,
      ...validate(
        { name: "city" + index, value: city?.city?.name },
        { required: true }
      ),
    };
    if (city.rawda_reservation_date || city.rawda_males_count || city.rawda_females_count) {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: "rawda_reservation_date" + index, value: city?.rawda_reservation_date, },
          { required: true }
        ),
        ...submitErrors,
        ...validate(
          { name: "rawda_females_count" + index, value: city?.rawda_females_count },
          { required: city?.rawda_females_count === "0" ? false : true }
        ),
        ...submitErrors,
        ...validate(
          { name: "rawda_males_count" + index, value: city?.rawda_males_count },
          {
            required:
              city?.rawda_males_count === "0"
                ? city?.rawda_females_count === "0"
                  ? true
                  : false
                : true,
          }
        ),
      };
    }
  });

  cities.forEach((city, indexCity) => {
    city.hotel.forEach((hote, index) => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: "hotel" + indexCity + index, value: hote?.hotel?.value },
          { required: true }
        ),
        ...validate(
          { name: "check_in_date" + indexCity + index, value: hote?.check_in_date },
          { required: true, date: true }
        ),
        ...validate(
          { name: "no_nights" + indexCity + index, value: hote.no_nights },
          { required: true }
        ),
        ...validate(
          { name: "no_nights" + indexCity + index, value: hote.no_nights },
          { required: true }
        ),
        ...validate(
          {
            name: "meal_type" + indexCity + index,
            value: hote?.meal_type?.name,
          },
          { required: true }
        ),
      };
    });
  });

  setErrors({
    ...submitErrors,
  });
  return submitErrors;
}

// check catring tab errors
export function checkCateringErrors({ OperationStatement, setErrors }) {

  let submitErrors = { pax: { required: false } };
  if (!OperationStatement?.catering || OperationStatement?.catering?.length === 0 || +OperationStatement?.catering_type !== 1) {
    return submitErrors
  };

  OperationStatement?.catering?.forEach((cater, index) => {
    cater?.catering?.forEach((cate, Ind) => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: "pax" + index + Ind, value: cate?.pax },
          { required: true, maxNumber: +OperationStatement?.adults }
        ),
        ...validate(
          { name: "meal_type" + index + Ind, value: cate?.meal_type?.id },
          { required: true }
        ),
        ...validate(
          { name: "supplier_name" + index + Ind, value: cate?.supplier_name },
          { required: true }
        ),
      };
    });
  });
  setErrors(submitErrors);
  return submitErrors;
}

// check transportation tab errors
export function checkTransportationErrors({ transportationData, attractionsData, setErrors }) {
  const validationsKeys = ["cycle_type", "request_type", "transporter_id"];
  let submitErrors = {}

  const attractionFormErrors = checkAttractionErrors({ attractionsData, setErrors });

  validationsKeys?.forEach(key => {
    submitErrors = {
      ...submitErrors,
      ...validate(
        {
          name: key,
          value: key === "transporter_id" ? transportationData[key]?.name : transportationData[key]
        },
        { required: true }
      ),
    }
  });

  transportationData?.vehicles?.forEach((vehicle, vehicleIndex) => {
    Object.keys(vehicle).forEach(key => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: `${key}-${vehicleIndex}`, value: vehicle[key] },
          { required: true }
        ),
      }
    })
  })

  setErrors({ ...submitErrors, ...attractionFormErrors });
  return { ...submitErrors, ...attractionFormErrors }
}


export function checkAttractionErrors({ attractionsData, setErrors }) {
  const validationsKeys = ["attractions_ids", "city_id", "date_time"]
  let submitErrors = {}
  attractionsData?.forEach((attractionItem, index) => {
    validationsKeys?.forEach(key => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: `${key}-${index}`, value: attractionItem[key] },
          { required: true, }
        ),
      }
    })
  });
  setErrors({ ...submitErrors });
  return submitErrors;
}



export function checkVisaErrors({ OperationStatement, setErrors }) {
  const validationsKeys = ["visa_type", "adults"]
  let submitErrors = {}
  OperationStatement.visas?.forEach((visaItem, index) => {
    validationsKeys?.forEach(key => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: `${key}-${index}`, value: visaItem[key] },
          {
            required: true,
            maxNumber: key === "adults" ? +OperationStatement?.adults : false,
          }
        ),
      }
    })
  });
  setErrors({ ...submitErrors });
  return submitErrors;
};

export function checkOtherServicesErrors({ OperationStatement, setErrors }) {
  const validationsKeys = ["name", "pax"];
  let submitErrors = {}
  OperationStatement?.other_services?.forEach((service, index) => {
    validationsKeys?.forEach(key => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: `${key}-${index}`, value: service[key] },
          {
            required: true,
            maxNumber: key === "pax" ? +OperationStatement?.adults : false,
          }
        ),
      }
    })
  });
  setErrors({ ...submitErrors });
  return submitErrors;
};