
const allAvailableTabs = [
  "operation-ArrivalDeparture",
  "operation-hotels",
  "operation-catering",
  "operation-transportation",
  "operation-visa",
  "operation-other",
  "operation-passports"
]
export function getOperationsLookup(Locale) {
  const { operationStatement } = Locale;

  let statementTypeList = [
    {
      id: "visa",
      name: operationStatement.visaOnly,
      // match operations statement tabs url key
      availableTabs: [
        "operation-ArrivalDeparture",
        "operation-hotels",
        "operation-catering",
        "operation-visa",
        "operation-other",
        "operation-passports"
      ]
    },
    {
      id: "visa_hotel",
      name: operationStatement.visaPlusHotel,
      // availableTabs  operations statement tabs url key
      availableTabs: [
        "operation-ArrivalDeparture",
        "operation-hotels",
        "operation-catering",
        "operation-visa",
        "operation-other",
        "operation-passports"
      ]
    },
    {
      id: "visa_hotel_internal_transportation",
      name: operationStatement.visaPlusHotelPlusInternalTransportation,
      availableTabs: allAvailableTabs
    },
    {
      id: "package_without_visa",
      name: operationStatement.packageWithoutVisa,
      // match operations statement tabs url key
      availableTabs: [
        "operation-ArrivalDeparture",
        "operation-hotels",
        "operation-catering",
        "operation-transportation",
        "operation-other",
        "operation-passports"
      ]
    },
    {
      id: "visa_internal_transportation",
      name: operationStatement.visaPlusInternalTransportation,
      availableTabs: allAvailableTabs
    },
    {
      id: "accommodation",
      name: operationStatement.accommodationOnly,
      availableTabs: [
        "operation-hotels",
        "operation-catering",
        "operation-passports"
      ]
    },
    {
      id: "internal_transportation",
      name: operationStatement.internalTransportationOnly,
      availableTabs: [
        "operation-ArrivalDeparture",
        "operation-transportation",
        "operation-passports"
      ]
    }
  ];

  let citiesLookup = [
    { id: 3, name: operationStatement.mecca },
    { id: 4, name: operationStatement.alMadinahAlMunawarah },
    { id: 194, name: operationStatement.riyadh },
    { id: 134, name: operationStatement.Jeddah },
    { id: 214, name: operationStatement.taif },
    { id: 224, name: operationStatement.Yanbu },
  ];

  let mealsTypesLookup = [
    { id: "all_inclusive", name: operationStatement.allInclusive, },
    { id: "breakfast_dinner", name: operationStatement.breakfastPlusDinner },
    { id: "breakfast", name: operationStatement.breakfast },
    { id: "no_meals", name: operationStatement.noMeals }
  ];

  let movmentTypesLookup = [
    { id: "arrival", name: operationStatement.Arrival },
    { id: "attraction", name: operationStatement.Attractions },
    { id: "internal_movement", name: operationStatement.internalMovement, },
    { id: "departure", name: operationStatement.Departure },
  ];

  let cycleTypesLookup = [
    { id: "full-cycle-with-attractions", name: operationStatement.fullCycleWithAttractions },
    { id: "full-cycle-without-attractions", name: operationStatement.fullCycleWithoutAttractions },
    { id: "arrival-and-return", name: operationStatement.arrrivalAndReturn },
  ];

  let requestTypeLookup = [
    { id: "through-umrah-operator", name: operationStatement.throughUmrahOperator },
    { id: "through-external-agent", name: operationStatement.throughExternalAgent },
  ];

  let visaTypesLookup = [
    { id: "Umra", name: operationStatement.umrah },
    { id: "tourism", name: operationStatement.tourism },
    { id: "Personal visit", name: operationStatement.personalVisit },
    { id: "Family visit", name: operationStatement.familyVisit },
  ];

  let transportTypesLookup = [
    { id: "Air", name: operationStatement.air },
    { id: "Land", name: operationStatement.land },
  ]

  return {
    statementTypeList,
    citiesLookup,
    mealsTypesLookup,
    movmentTypesLookup,
    cycleTypesLookup,
    requestTypeLookup,
    visaTypesLookup,
    transportTypesLookup
  }
}