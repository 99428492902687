import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import OperationStatementHeader from "./StatementHeader";
import Hotels from "./OperationElements/Hotels";
import OperationDetails from "./OperationElements/OperationDetails";
import OperationTabs from "modules/UmrahOperations/shared/Tabs";
import { ReactComponent as OperatingDetailsIcon } from "assets/images/umrah-operations/details.svg";
import { ReactComponent as OperatingHotelsIcon } from "assets/images/umrah-operations/hotels.svg";
import { ReactComponent as OperatingArrivalDepartureIcon } from "assets/images/umrah-operations/arrival-departure.svg";
// import { ReactComponent as OperatingAttractionIcon } from "assets/images/umrah-operations/attraction-icon.svg";
import { ReactComponent as OperatingTransportationIcon } from "assets/images/umrah-operations/car-icon.svg";
import { ReactComponent as OperatingVisaIcon } from "assets/images/umrah-operations/visa-icon.svg";
import { ReactComponent as OperatingOtherIcon } from "assets/images/umrah-operations/other-services-icon.svg";
import { ReactComponent as OperatingCateringIcon } from "assets/images/umrah-operations/catering.svg";
// import { PassportIcon } from 'modules/UmrahOperations/shared/Icons';
import Locale from "translations";
import BreadCrump from "modules/UmrahOperations/shared/BreadCrump";
import TextField from "components/Form/TextField/TextField";
import { ReactComponent as ExportExcelIcon } from "assets/images/umrah-operations/export-excel-icon.svg";
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import ArrivalDeparture from "./OperationElements/ArrivalDeparture";
import { exportExcelSingleOperation, getOperationStatement, submitOperationProduct } from "services/operationStatement";
import { useParams } from "react-router-dom";
import { formatViewOperationStatement } from "modules/UmrahOperations/helper/formatUmrahOperations";
import OperationStatementVisa from "./OperationElements/Visa";
import OperationStatementAttractions from "./OperationElements/Attractions";
import OperationStatementOtherServices from "./OperationElements/OtherServices";
import OperationStatementTransportation from "./OperationElements/Transportation";
import OperationStatementCatering from "./OperationElements/Catering";
// import OperationStatementPassports from './OperationElements/Passports';
import moment from 'moment';
import { store } from 'react-notifications-component';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { useSBSState } from 'context/global';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import useOperationForms from './OperationElements/useOperationForms';
import { isEqual } from "lodash"



export default function PublicOperationStatementView() {
	const { locale } = useSBSState();
	const { operationStatement, companyDetails, interests } = Locale;
	// const { isAuth } = useSBSState();
	const operationDataCache = useRef(null);

	const { reference, id } = useParams();
	const location = useLocation();
	const isPublic = location.pathname.includes("public");
	const statementId = isPublic ? id : reference;

	const [OperationStatement, setOperationStatement] = useState({
		operation_details: {
			adults: 0,
			children: 0,
			infants: 0,
			agent_id: null,
		},
		arrival_departure: {},
		hotels: [],
		visas: [],
		attractions: [],
		other_services: [],
		transportation: {
			cycle_type: null,
			request_type: null,
			transporter_id: null,
			credential_number: "",
			operation_id: "",
			vehicles: [],
			movements: [],
		},
	});

	const [activeTab, setActiveTab] = useState("operation-details");
	const statementType = OperationStatement.operation_details?.statement_type
	const {
		submitOperationDetailsForm,
		submitOperationTripForm,
		submitOperationHotelForm,
		submitOperationCatringForm,
		submitOperationTransportationForm,
		submitOperationVisaForm,
		submitOperationOtherServiceForm,
		errors,
		setErrors
	} = useOperationForms();

	async function getStatementMain() {
		const response = await getOperationStatement(id);
		if (response.status === 200 || response.status === 201) {
			let data = await formatViewOperationStatement(response.data.data, Locale);
			setOperationStatement(data);
		}
	}

	useEffect(() => {
		if (id) {
			getStatementMain();
		}
	}, []);

	const operationTabsItems = useMemo(() => {
		return [
			{
				title: operationStatement.details,
				icon: <OperatingDetailsIcon />,
				url: "operation-details",
				formSubmition: () => submitOperationDetailsForm({ operation_details: OperationStatement?.operation_details })
			},

			{
				title: operationStatement.ArrivalDeparture,
				icon: <OperatingArrivalDepartureIcon />,
				url: "operation-ArrivalDeparture",
				disabled: statementId &&
					statementType?.availableTabs?.includes("operation-ArrivalDeparture") ? false : true,
				formSubmition: () => submitOperationTripForm({ arrival_departure: OperationStatement?.trip })
			},
			{
				title: operationStatement.hotel,
				icon: <OperatingHotelsIcon />,
				url: "operation-hotels",
				disabled:
					statementId &&
						statementType?.availableTabs?.includes("operation-hotels") ? false : true,
				formSubmition: () => submitOperationHotelForm({ cities: OperationStatement?.hotels })
			},
			{
				title: operationStatement.catering,
				icon: <OperatingCateringIcon />,
				url: "operation-catering",
				disabled: statementId &&
					OperationStatement.hotels.length > 0 &&
					statementType?.availableTabs?.includes("operation-catering") ? false : true,
				formSubmition: () => submitOperationCatringForm({ OperationStatement })
			},
			{
				title: operationStatement.transportation,
				icon: <OperatingTransportationIcon />,
				url: "operation-transportation",
				disabled: statementId &&
					!OperationStatement?.operation_details?.without_movements &&
					statementType?.availableTabs?.includes("operation-transportation") ? false : true,
				formSubmition: () => submitOperationTransportationForm({
					transportationData: OperationStatement?.transportation,
					attractionsData: OperationStatement?.attractions
				})
			},
			{
				title: operationStatement.visa,
				icon: <OperatingVisaIcon />,
				url: "operation-visa",
				disabled: statementId && statementType?.availableTabs?.includes("operation-visa") ? false : true,
				formSubmition: () => submitOperationVisaForm({ OperationStatement })
			},
			{
				title: operationStatement.otherServices,
				icon: <OperatingOtherIcon />,
				url: "operation-other",
				disabled: statementId && statementType?.availableTabs?.includes("operation-other") ? false : true,
				formSubmition: () => submitOperationOtherServiceForm({ OperationStatement })
			},
		]
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [OperationStatement, statementId, statementType?.availableTabs]);

	const currentTabIndex = operationTabsItems.findIndex(
		(res) => res.url === activeTab
	);

	const isDisabled =	OperationStatement?.status !== "draft"  && OperationStatement?.status !== "new";


	function handleNextPrev(direction) {
		let nextPrevAvailableIndex = direction === "next" ? currentTabIndex + 1 : currentTabIndex - 1;
		while (operationTabsItems[nextPrevAvailableIndex]?.disabled) {
			nextPrevAvailableIndex = direction === "next" ? nextPrevAvailableIndex + 1 : nextPrevAvailableIndex - 1;
			if (nextPrevAvailableIndex < 0 || nextPrevAvailableIndex >= operationTabsItems.length) {
				return;
			}
		}
		onTabChange(operationTabsItems[nextPrevAvailableIndex]?.url)
	}

	// create function to trigger when tab changes
	// this function will work on click on tab and (next-prev) buttons
	const onTabChange = useCallback(async (nextActiveTab) => {
		// check if current active tab touched
		if (isEqual(OperationStatement, operationDataCache.current) && nextActiveTab !== activeTab) {
			setActiveTab(nextActiveTab);
			return;
		}
		// submit current index tab form this form will return promise 
		const currentFormSubmition = operationTabsItems[currentTabIndex].formSubmition;
		// if the submition is ok switch to target tab
		const response = await currentFormSubmition();

		if (response?.status >= 200 && response?.status < 300) {
			// update cache
			operationDataCache.current = { ...OperationStatement };
			// change current tab
			setActiveTab(nextActiveTab);
			// reset errors state
			setErrors({});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [OperationStatement, currentTabIndex]);


	const submitProduct = async () => {
		const response = await submitOperationProduct(statementId);
		if (response.status === 200 || response.status === 201) {
			store.addNotification({
				title: `${interests.messages.updateSuccessfully}`,
				message: `${interests.messages.EntriesUpdateSuccess}`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};


	const exportExcel = async () => {
		const res = await exportExcelSingleOperation(id);
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`operation-statement-${moment().format("DD-MM-YYYY")}.xlsx`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	// useEffect(() => {
	// 	const currentLocale = localStorage.getItem("currentLocale");
	// 	if (!currentLocale || currentLocale !== "ar") {
	// 		localStorage.setItem("currentLocale", "ar");
	// 		window.location.reload();
	// 	}
	// }, [])

	return (
		<div className="operation-statement-view">
			<div className='d-flex justify-content-end px-5 pt-3'>
				<LanguageSwitcher />
			</div>
			<div className="container-fluid px-5">
				<BreadCrump title={operationStatement.operatingStatement}>
					<div className="">
						<TextField

							type="text"
							id="statement-operation-no"
							name="statement-operation-no"
							hasLabel={false}
							placeholder={operationStatement.quickOperationNo}
							isImage={true}
							image={SeachIcon}
							prependImage={true}
						// onChange={(e) => {
						//   setFilters({
						//     ...filters,
						//     search: e.target.value,
						//   });
						// }}
						// value={filters?.search}
						/>
					</div>
					<button className="btn export-excel-btn mx-2" onClick={exportExcel}>
						<ExportExcelIcon />
						<span className="mx-1">{operationStatement.exportExcel}</span>
					</button>
				</BreadCrump>

				<OperationStatementHeader OperationStatement={OperationStatement} />

				<div className="operating-view-container mt-4">
					<div className="w-100">
						<div className="w-100 d-flex justify-content-between align-items-center flex-wrap my-3">
							<p className="operating-title-view">
								{operationStatement.AddNewOperatingStatement}
								
							</p>
							<div className="d-flex align-content-center">
								<button
									disabled={currentTabIndex === 0 || !statementId}
									onClick={() => handleNextPrev("prev")}
									className={`operating-btn-back mx-1 ${currentTabIndex === 0 || !statementId ? "disabled" : ""}`}
								>
									{locale === "en" ? (
										<i class="fas fa-arrow-left mx-2"></i>
									) : (
										<i class="fas fa-arrow-right mx-2"></i>
									)}

									{companyDetails.back}
								</button>

								<button
									disabled={currentTabIndex === operationTabsItems.length - 1 || !statementId}
									onClick={() => handleNextPrev("next")}
									className={`operating-btn-next mx-1 ${currentTabIndex === operationTabsItems.length - 1 || !statementId ? "disabled" : ""}`}
								>
									{companyDetails.next}
									{locale === "en" ? (
										<i class="fas fa-arrow-right mx-2"></i>
									) : (
										<i class="fas fa-arrow-left mx-2"></i>
									)}
								</button>
							</div>
						</div>
						<OperationTabs
							tabs={operationTabsItems}
							activeTab={activeTab}
							IsNotPushUrl={true}
							// setActiveTab={setActiveTab}
							setActiveTab={onTabChange}
							classNames=" col-md-12 col-12  mx-auto"
						/>
					</div>

					<div className="col-md-12 mt-4">
						{activeTab === "operation-details" ? (
							<OperationDetails
								isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								submitProduct={submitProduct}
								formSubmition={() => onTabChange(activeTab)}
								errors={errors}
								setErrors={setErrors}
							/>
						) : null}

						{activeTab === "operation-hotels" ? (
							<Hotels
							isDisabled={isDisabled}
								getStatementMain={getStatementMain}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								submitProduct={submitProduct}
								formSubmition={() => onTabChange(activeTab)}
								errors={errors}
								setErrors={setErrors}
							/>
						) : null}

						{activeTab === "operation-ArrivalDeparture" ? (
							<ArrivalDeparture
								isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								submitProduct={submitProduct}
								formSubmition={() => onTabChange(activeTab)}
								errors={errors}
								setErrors={setErrors}
							/>
						) : null}
						{/* attractions */}
						{activeTab === "operation-attraction" ? (
							<OperationStatementAttractions
							isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								submitProduct={submitProduct}
								formSubmition={() => onTabChange(activeTab)}
								errors={errors}
								setErrors={setErrors}
							/>
						) : null}
						{/* Transportation */}
						{(activeTab === "operation-transportation") ? (
							<OperationStatementTransportation
							isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								submitProduct={submitProduct}
								formSubmition={() => onTabChange(activeTab)}
								errors={errors}
								setErrors={setErrors}
							/>
						) : null}
						{/* visa */}
						{activeTab === "operation-visa" ? (
							<OperationStatementVisa
							isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								submitProduct={submitProduct}
								formSubmition={() => onTabChange(activeTab)}
								errors={errors}
								setErrors={setErrors}
							/>
						) : null}
						{/* other services */}
						{activeTab === "operation-other" ? (
							<OperationStatementOtherServices
							isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								submitProduct={submitProduct}
								formSubmition={() => onTabChange(activeTab)}
								errors={errors}
								setErrors={setErrors}
							/>
						) : null}
						{/* Catering */}
						{activeTab === "operation-catering" ? (
							<OperationStatementCatering
							isDisabled={isDisabled}
								OperationStatement={OperationStatement}
								setOperationStatement={setOperationStatement}
								submitProduct={submitProduct}
							/>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
}
