import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/v1";
const serviceBuilderURL = URL + "/";
const builderApiUri = process.env.REACT_APP_PRODUCTS_BUILDER;

export const getAllServicesList = async (params) => {
  return await axios.get(
    `${serviceBuilderURL}builder-services/main`,
    { params }
  );
};

export const getServicesById = async (id) => {
  return await axios.get(
    `${serviceBuilderURL}builder-services/main/${id}`,
  )
};

export const deleteServiceById = async (id) => {
  return await axios.delete(
    `${serviceBuilderURL}builder-services/main/${id}`,
  );
};

export const addHotelService = async (data) => {
  return await axios.post(`${serviceBuilderURL}builder-services/hotels`, data);
};

export const addAttractionService = async (data) => {
  return await axios.post(`${serviceBuilderURL}builder-services/attractions`, data);
};

export const addOtherService = async (data) => {
  return await axios.post(`${serviceBuilderURL}builder-services/others`, data);
};

export const updateOtherService = async (id, data) => {
  return await axios.put(`${serviceBuilderURL}builder-services/others/${id}`, data);
}

export const editHotelService = async (id, data) => {
  return await axios.put(`${serviceBuilderURL}builder-services/hotels/${id}`, data);
};

export const addFlightService = async (data) => {
  return await axios.post(`${serviceBuilderURL}builder-services/flights`, data);
};

export const getServiceById = async (id) => {
  return await axios.get(
    `${serviceBuilderURL}builder-services/main/${id}`,
  );
};


export const updateService = async (id, type, data) => {
  return await axios.put(`${serviceBuilderURL}builder-services/${type}/${id}`, data);
}

export const editFlightService = async (id, data) => {
  return await axios.put(`${serviceBuilderURL}builder-services/flights/${id}`, data);
};

export const addTransferService = async (data) => {
  return await axios.post(`${serviceBuilderURL}builder-services/transfers`, data);
};

export const editTransferService = async (id, data) => {
  return await axios.put(`${serviceBuilderURL}builder-services/transfers/${id}`, data);
};

export const addPackageService = async (data) => {
  return await axios.post(`${serviceBuilderURL}builder-services/packages`, data);
};

export const editPackageService = async (id, data) => {
  return await axios.put(`${serviceBuilderURL}builder-services/packages/${id}`, data);
};


export const fetchCountryPortsWithSearch = async (id = '', searchQuery) => {
  let url = `${builderApiUri}/ports_with_halls?transportation_type_id=${2}`;
  if (id) {
    url += `&country_id=${id}`;
  }
  if (searchQuery) {
    url += `&search=${searchQuery}`;
  }
  return await axios
    .get(
      url
    )
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const updateServiceStatus = async (params) => {
  return await axios
    .put(
      `${serviceBuilderURL}builder-services/main/${params?.id}`, {
      in_website: params?.in_website,
      status: params?.status
    }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const addServiceLanguages = async (id, data) => {
  return await axios.post(`${serviceBuilderURL}builder-services/set-languages/${id}`, data);
};


export const addServiceTranslate = async (id, serviceType,data) => {
  return await axios.post(`${serviceBuilderURL}builder-services/${serviceType}/set-lang/${id}`, data);
};

