import { isFormValid } from "helpers/validate";
import { handleResponesNotification } from "modules/UmrahOperations/helper/accountingHelpers";
import {
  addCateringStatements,
  addEditOtherServices,
  addEditTransportation,
  addEditTransportationWithAttraction,
  addEditVisa,
  addHotelStatements,
  addNewStatements,
  addNewStatementsPublic,
  addTripStatements,
  updateNewStatements
} from "services/operationStatement";
import { useParams, useHistory } from "react-router-dom";
import Locale from "translations";
import { useState } from "react";
import {
  checkFormKeysErrors,
  checkArrivalDepartureErrors,
  checkCateringErrors,
  checkCitiesErrors,
  checkTransportationErrors,
  checkVisaErrors,
  checkOtherServicesErrors
} from "./operationFormValidations";
import moment from "moment";

export default function useOperationForms() {
  const { interests } = Locale;
  const { reference, id, bundle } = useParams();
  const history = useHistory();
  const statementId = reference || id;


  // states
  const [errors, setErrors] = useState({});


  // save details tab
  async function submitOperationDetailsForm({ operation_details, cb }) {
    // checkerrors
    // const operation_details = OperationStatement?.operation_details;
    const validationKeys = ["email", "phone", "country", "statement_type", "agent"];
    const formHasErrors = !isFormValid(checkFormKeysErrors({ keys: validationKeys, values: operation_details, setErrors }));

    if (formHasErrors) return;
    let response;

    // submit form and execute callback function
    let data = {
      statement_type: operation_details.statement_type?.id,
      file_number: operation_details?.file_number,
      adults: +operation_details?.adults,
      children: +operation_details?.children,
      infants: +operation_details?.infants,
      responsible_name: operation_details?.responsible_name,
      responsible_phone: operation_details?.responsible_phone,
      responsible_phone_country_id: operation_details?.responsible_phone_code?.id,
      with_movements: !operation_details?.without_movements
    }
    data = {
      ...data,
      ...(operation_details?.agent_id != null
        ? { agent_id: operation_details?.company_category === "tourism_company" ? null : operation_details?.agent_id }
        : {
          agent: {
            name: operation_details?.agent?.name,
            email: operation_details?.email,
            phone_code: operation_details?.phoneCode?.phone_code || operation_details?.phoneCode,
            phone: operation_details?.phone,
            country_id: operation_details?.country?.id,
          }
        })
    }

    if (window.location.pathname.includes("public")) {
      response = await addNewStatementsPublic(statementId, data);
    } else {
      response = statementId ? await updateNewStatements(statementId, data) : await addNewStatements(data);
    }
    if (!window.location.pathname.includes("public") && response?.status === 201) {
      history.push(`/${bundle}/operation-view/${response?.data?.data?.reference_number}`);
      return
    }
    if (response.status === 200 || response.status === 201) {
      handleResponesNotification({
        alertType: "success",
        title: interests.messages.updateSuccessfully,
        message: interests.messages.EntriesUpdateSuccess,
      });
      cb && cb(response);
    }
    return response;
  }

  // save arrival departure tab
  async function submitOperationTripForm({ arrival_departure, cb }) {
    const formErrors = checkArrivalDepartureErrors({ arrival_departure, setErrors });
    if (!isFormValid(formErrors)) return;
    const isTransportrAir = arrival_departure?.transport_type?.id === "Air";

    let requestData = {
      transport_type: arrival_departure?.transport_type?.id?.toString(),
      arrival_date: arrival_departure?.arrival_time ?
        `${moment(arrival_departure?.arrival_date).format("YYYY-MM-DD")}T${arrival_departure?.arrival_time}`
        :
        moment(arrival_departure?.arrival_date).utc(true),
      arrival_trip_number: arrival_departure?.arrival_trip_number,
      arrival_port_id: arrival_departure?.arrival_port_id?.id,
      arrival_hall_id: arrival_departure?.arrival_hall_id?.id,
      // departures data
      departures: arrival_departure.departures.map((departure) => {
        return {
          id: departure?.id || null,
          carrier_id: departure?.departure_carrier_name?.id,
          trip_number: isTransportrAir ? departure?.departure_trip_number : null,
          // date: moment(departure?.departure_date).utc(true),
          date: departure?.departure_time ?
            `${moment(departure?.departure_date).format("YYYY-MM-DD")}T${departure?.departure_time}`
            :
            moment(departure?.departure_date).utc(true),
          port_id: departure?.departure_port_id?.id,
          hall_id: isTransportrAir ? departure?.departure_hall_id?.id : null,
        };
      }),
      arrival_carrier_id: arrival_departure?.arrival_carrier_name?.id,
      arrival_carrier_name: arrival_departure?.arrival_carrier_name,
    }
    const response = await addTripStatements(statementId, requestData);
    if (response.status === 200 || response.status === 201) {

      handleResponesNotification({
        alertType: "success",
        message: interests.messages.EntriesUpdateSuccess,
        title: interests.messages.updateSuccessfully
      })
      cb && cb(response);
    }
    return response;
  }

  // save hotels tab
  async function submitOperationHotelForm({ cities, cb }) {
    const formErrors = checkCitiesErrors({ cities, setErrors });
    if (!isFormValid(formErrors)) return;

    let requestData = cities?.map((res) => {
      return {
        city_id: res.city.id,
        confirmation_number: res.confirmation_number,
        rawda_reservation_date: res.rawda_reservation_date
          ? moment(res.rawda_reservation_date).format("YYYY-MM-DD")
          : null,
        rawda_males_count: +res.rawda_males_count,
        rawda_females_count: +res.rawda_females_count,
        rawda_attachment: res.rawda_attachment || null,
        hotels: res.hotel.map((hote) => {
          return {
            ...hote,
            hotel_id: hote?.hotel?.id,
            meal_type: hote?.meal_type?.id,
            rooms_count: {
              single: +hote.single,
              double: +hote.double,
              triple: +hote.triple,
              quadruple: +hote.quadruple,
              quintuple: +hote.quintuple,
              sixtuple: +hote.sixtuple,
            },
          };
        })
      };
    })

    const response = await addHotelStatements(statementId, { cities: requestData });

    if (response.status === 200 || response.status === 201) {

      handleResponesNotification({
        alertType: "success",
        message: interests.messages.EntriesUpdateSuccess,
        title: interests.messages.updateSuccessfully
      })
      cb && cb(response);
    }
    return response;
  }

  // save catring tab
  async function submitOperationCatringForm({ OperationStatement, cb }) {

    const formErrors = checkCateringErrors({ OperationStatement, setErrors });
    if (!isFormValid(formErrors)) return;

    let requestData = [...OperationStatement?.catering]
      .map((catering) => {
        return catering?.catering?.map((res) => {
          return {
            hotel_id: catering?.hotel?.id,
            pax: res?.pax,
            meal_type: res?.meal_type?.name,
            supplier_name: res?.supplier_name,
          };
        });
      })
      .flat();


    const response = await addCateringStatements(statementId, {
      catering_type: +OperationStatement?.catering_type,
      catering: +OperationStatement?.catering_type === 1 ? requestData : [],
    });


    if (response.status === 200 || response.status === 201) {
      handleResponesNotification({
        alertType: "success",
        message: interests.messages.EntriesUpdateSuccess,
        title: interests.messages.updateSuccessfully
      })
      cb && cb(response);
    }
    return response;
  }

  // save transportation
  async function submitOperationTransportationForm({ transportationData, attractionsData, cb }) {
    const formErrors = checkTransportationErrors({ transportationData, attractionsData, setErrors });
    if (!isFormValid(formErrors)) return;

    const isCycleWithAttraction = transportationData?.cycle_type?.id === "full-cycle-with-attractions"

    const requestData = {
      transporter_id: transportationData?.transporter_id?.id,
      cycle_type: transportationData?.cycle_type?.id,
      request_type: transportationData?.request_type?.id,
      credential_number: transportationData?.credential_number,
      operation_id: transportationData?.operation_id,

      vehicles: transportationData?.vehicles?.map(vehicle => {
        return {
          vehicle_type_id: vehicle?.vehicle_type_id?.id,
          vehicle_model: vehicle?.vehicle_model?.id,
          count: +vehicle?.count,
        }
      }),

      movements: transportationData?.movements?.map(movement => {
        return {
          movement_type: movement?.movement_type?.id,
          movement_date: movement?.movement_date ? movement?.movement_date : null,
          pickup_points: movement?.pickup_points,
          destinations: movement?.destinations,
          pax: +movement?.pax,
          pickup_city_id: movement?.pickup_city_id?.id,
          destination_city_id: movement?.destination_city_id?.id,
          pickup_time: movement?.pickup_time ? moment(movement?.pickup_time).format("hh:mm") : null
        }
      }),
      ...(isCycleWithAttraction ? {
        attractions: attractionsData?.map(item => {
          return {
            attraction_ids: item?.attractions_ids?.map(attraction => attraction?.id),
            city_id: item?.city_id?.id,
            date_time: moment(item?.date_time).utc(true).toDate(),
          }
        })
      } : null)
    }

    const response = isCycleWithAttraction
      ? await addEditTransportationWithAttraction(statementId, requestData)
      : await addEditTransportation(statementId, requestData);

    if (response.status === 200 || response.status === 201) {

      handleResponesNotification({
        alertType: "success",
        message: interests.messages.EntriesUpdateSuccess,
        title: interests.messages.updateSuccessfully
      })
      cb && cb(response);
    }
    return response;
  }

  // save visa tab
  async function submitOperationVisaForm({ OperationStatement }) {
    const formErrors = checkVisaErrors({ OperationStatement, setErrors })

    if (!isFormValid(formErrors)) return;

    const requestData = {
      visas: OperationStatement?.visas?.map(visaItem => {
        return {
          id: visaItem?.id || null,
          visa_type: visaItem?.visa_type?.id,
          adults: +visaItem?.adults,
          children: +visaItem?.children,
          infants: +visaItem?.infants,
          issued_by: visaItem?.issued_by,
          nusuk_number: visaItem?.nusuk_number,
        }
      })
    };

    const response = await addEditVisa(statementId, requestData);

    if (response.status === 200 || response.status === 201) {
      handleResponesNotification({
        alertType: "success",
        message: interests.messages.EntriesUpdateSuccess,
        title: interests.messages.updateSuccessfully
      })
    }
    return response;
  }

  // save other services tab
  async function submitOperationOtherServiceForm({ OperationStatement, cb }) {
    const formErrors = checkOtherServicesErrors({ OperationStatement, setErrors });
    if (!isFormValid(formErrors)) return;

    const requestData = {
      services: OperationStatement?.other_services?.map(service => ({ name: service?.name, pax: +service?.pax }))
    };

    const response = await addEditOtherServices(statementId, requestData);

    if (response.status === 200 || response.status === 201) {


      handleResponesNotification({
        alertType: "success",
        message: interests.messages.EntriesUpdateSuccess,
        title: interests.messages.updateSuccessfully
      })
      cb && cb(response);
    }
    return response;
  }



  return {
    errors,
    setErrors,
    submitOperationDetailsForm,
    submitOperationTripForm,
    submitOperationHotelForm,
    submitOperationCatringForm,
    submitOperationTransportationForm,
    submitOperationVisaForm,
    submitOperationOtherServiceForm
  }
}

