import { AddElement } from "modules/WebBuilder-V2/shared/AddElement";
import AddService from "modules/WebBuilder-V2/shared/AddService";
import React, { useState } from "react";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { EditBox } from "modules/WebBuilder-V2/shared/EditBox";

import DragAndDropContainer from "modules/WebBuilder/shared/DragAndDropContainer";
import Carousel from "react-multi-carousel";
import CustomRightArrow from "modules/WebBuilder-V2/shared/CustomRightArrow";
import CustomLeftArrow from "modules/WebBuilder-V2/shared/CustomLeftArrow";
import Locale from "translations";
import { SORT_ITEMS_IN_CONTAINER_ACTION } from "modules/WebBuilder-V2/interfaces/blockTypes";
import FlightServiceCardTheme4 from "modules/WebBuilder-V2/Components/Content/Flights/Theme4Card";
import FlightServiceCardTheme5 from "modules/WebBuilder-V2/Components/Content/Flights/Theme5Card";
import FlightServiceBuilderCard from "modules/WebBuilder-V2/Components/Content/Flights/ServiceBuilderCard";

const flightBlocksType = "flightBlocksType";

export default function CustomPageFlights({
	details,
	pageId,
	focusContainer,
	onFocus,
	title,
	preview,
	uuid,
}) {
	const { webBuilder } = Locale;
	const { style } = useWebBuilderState();
	const { id, type, items, index, items_per_row } = details;

	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);

	const dispatch = useWebBuilderDispatch();

	function handleOpenAddModal() {
		setOpenAdd((prev) => !prev);
	}
	function openEditModal(item) {
		handleOpenAddModal();
		setUpdateItem(item);
	}

	function editItem(item, index) {
		return [
			<span
				className="pointer-event"
				key={`${item.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
			>
				<SettingIcon />
			</span>,
		];
	}

	function deleteBodyContent(itemId) {
		dispatch({
			type: "deleteItemFromCustomPage",
			payload: {
				sectionIndex: index,
				itemId,
				pageId,
			},
		});
	}
	function itemsBoxActions(itemId) {
		return [
			<span
				className="pointer-event"
				key={itemId}
				onClick={() => deleteBodyContent(itemId)}
			>
				<TrashIcon />
			</span>,
		];
	}

	function addItemToFlight(item) {
		updateItem
			? dispatch({
					type: "editItemToCustomPage",
					payload: {
						sectionIndex: index,
						item,
						itemIndex: updateItem.index,
						pageId,
						pageUUID: uuid,
					},
			  })
			: dispatch({
					type: "addItemToCustomPage",
					payload: {
						sectionIndex: index,
						item,
						pageId,
						pageUUID: uuid,
					},
			  });
		setUpdateItem(null);
	}

	function sortFlightsItems({ dragIndex, hoverIndex }) {
		// dispatch({
		//   type: "sortItemsInContainer",
		//   payload: {
		//     sectionId: id,
		//     dragIndex,
		//     hoverIndex,
		//   },
		// });
	}

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 3,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 567 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 567, min: 0 },
			items: 1,
		},
	};

	function renderFlightView(item) {
		switch (style.theme) {
			case "theme-1":
			case "theme-2":
			case "theme-4":
				return <FlightServiceCardTheme4 {...item} itemType="flight" />;
			case "theme-5":
			case "theme-7":
				return <FlightServiceCardTheme5 {...item} itemType="flight" />;
			case "theme-10":
				return <FlightServiceCardTheme4 {...item} itemType="flight" />;
			default:
				return <FlightServiceBuilderCard {...item} itemType="flight" />;
		}
	}

	const selectedIds = items ? items.map((i) => i.id) : [];

	return (
		<div className={`web-builder-content-flights`}>
			{(items?.length > 0 || !preview) && (
				<h3 className="section-title">{title}</h3>
			)}
			<EditBox editMode={focusContainer === type} defaultIcon={""}>
				<div
					id={id}
					className="web-builder-content-hotels"
					onClick={() => onFocus(type)}
				>
					{!preview && (
						<div className="service-top-section">
							<AddElement title="Add Flights" onClick={handleOpenAddModal} />
						</div>
					)}

					{items?.[0]?.sourceModule === "service-builder" ? (
						// service builder cards
						<div className="services-carousel-container">
							<Carousel
								responsive={responsive}
								itemClass={"hotel-slide"}
								className=""
								slidesToSlide={1}
								keyBoardControl={true}
								customRightArrow={<CustomRightArrow />}
								customLeftArrow={<CustomLeftArrow />}
								renderButtonGroupOutside={true}
								removeArrowOnDeviceType={["tablet", "mobile"]}
								renderArrowsWhenDisabled={true}
								infinite={true}
							>
								{items.map((item, index) => (
									<DragAndDropContainer
										accept={flightBlocksType}
										type={flightBlocksType}
										id={item.id}
										index={index}
										action={SORT_ITEMS_IN_CONTAINER_ACTION}
										moveItemInContainer={(dragIndex, hoverIndex) =>
											sortFlightsItems({ dragIndex, hoverIndex })
										}
										key={item.id}
									>
										<EditBox
											editMode={focusContainer === type}
											actions={[
												...editItem(item, index),
												...itemsBoxActions(item.id),
											]}
										>
											<div className="px-2" key={item.id || index}>
												<FlightServiceBuilderCard
													{...item}
													openEditModal={openEditModal}
												/>
											</div>
										</EditBox>
									</DragAndDropContainer>
								))}
							</Carousel>
						</div>
					) : (
						// package builder cards
						<div
							className={"flights-cards-container"}
							style={{ gridTemplateColumns: `repeat(${+items_per_row} , 1fr)` }}
						>
							{items.map((item, index) => (
								<DragAndDropContainer
									accept={flightBlocksType}
									type={flightBlocksType}
									id={item.id}
									index={index}
									action={SORT_ITEMS_IN_CONTAINER_ACTION}
									moveItemInContainer={(dragIndex, hoverIndex) =>
										sortFlightsItems({ dragIndex, hoverIndex })
									}
									key={item.id}
								>
									<EditBox
										editMode={focusContainer === type}
										actions={[
											...editItem(item, index),
											...itemsBoxActions(item.id),
										]}
									>
										{renderFlightView(item)}
									</EditBox>
								</DragAndDropContainer>
							))}
						</div>
					)}
					{openAdd && (
						<AddService
							handleModalState={handleOpenAddModal}
							open={openAdd}
							item_id={1}
							label={webBuilder.flight}
							header={webBuilder.flights}
							onAdd={addItemToFlight}
							details={updateItem}
							selectedIds={selectedIds}
							showPackageBuilderOption={
								items.length === 0
									? true
									: items?.[0].sourceModule === "package-builder"
							}
						/>
					)}
				</div>
			</EditBox>
		</div>
	);
}
