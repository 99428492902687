import Header from "components/Header/Header";
import { useSBSDispatch, useSBSState } from "context/global";
import { useEffect, useState } from "react";
import { getDebit } from "services/wallet";
import { useHistory, useLocation } from "react-router-dom";
import NavigationBar from './NavigationBar';
import AppFooter from './AppFooter';
import { NavbarDismissConstants } from 'helpers/constants';

export default function DashboardLayout(props) {
	const dispatch = useSBSDispatch();
	const { companyInfo } = useSBSState();
	const history = useHistory();
	const location = useLocation()
	const [routeContainsString, setRouteContainsString] = useState(true);
	const isServiceCompnay = companyInfo?.company_category === "services_company"
	const search = window.location.search;
	const name = new URLSearchParams(search);

	const checkRoute = () => {
		let exists = false;
		for(let i=0; i< NavbarDismissConstants.length; i++){
			if(location.pathname.includes(NavbarDismissConstants[i])){
				exists = true;
				break;
			}
		}
    return exists;
  };

	useEffect(()=>{
		if(checkRoute())
			setRouteContainsString(true)
		else setRouteContainsString(false)
	}, [location])

	useEffect(() => {
		let x = {};
		for (const [key, value] of name) {
			x = { ...x, [key]: value };
		}

		if (x.hasOwnProperty("token")) {
		} else {
			// async function fetchSafaWallet() {
			// 	const res = await getCredit();
			// 	dispatch({ type: "getSafaBalance", payload: res?.data?.data });
			// }

			async function fetchMyWallet() {
				const res = await getDebit();
				dispatch({ type: "getMyBalance", payload: res?.data?.data?.[0] });
			}
			if (!isServiceCompnay) {
				// fetchSafaWallet();
				fetchMyWallet();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	useEffect(() => {
		if (!companyInfo?.company_category && location.pathname !== "/company-profile" && location.pathname !== "/edit-company-profile") {
			history.push('/company-profile')
		}
	}, [companyInfo?.company_category, history, location.pathname])

	const path = window.location.href;
	if (path.includes("/umrah-forum")) {
		return props.children
	} else {
		return (
			<div className={` ${path.includes("umrah-operations") ? "bg-white" : "bg-gray-100"}  ${!path.includes("hyperpay") ? "vh-100" : ""}`}>
				<Header />
				{
					!routeContainsString && (
						<NavigationBar />
					)
				}
				<div className="container_layout " {...props}>
					{props.children}
				</div>
				<AppFooter />
			</div>
		);
	}
}
