import {
  CrowdManagementIcon,
  EnhancingUmrahIcon,
  ImprovementIcon,
  InnovationIcon,
  IOTIcon,
  LogisticsIcon,
  RaisingUmrahLevelIcon,
  UmrahAccommodationsIcon,
  UmrahPlusIcon
} from './components/Icons';

import umrahSmartSolution from "./assets/groups/umrah-smart-solution.jpg"
import logistics from "./assets/groups/logistics.jpg"
import accommodations from "./assets/groups/accommodations.jpg"
import crowdManagement from "./assets/groups/crowd-management.jpg"
import enhancingUmrahExperience from "./assets/groups/enhancing-umrah-experience.jpg"
import improvement from "./assets/groups/improvement.jpg"
import innovation from "./assets/groups/innovation.jpg"
import raisingUmrahService from "./assets/groups/raising-umrah-service-level.jpg"
import umrahPlus from "./assets/groups/umrah-plus.jpg"



export const forumGroupsList = [
  // 
  {
    title: "⁠الحلول الذكية للعمرة: استخدام إنترنت الأشياء والبيانات الضخمة",
    description: `
    أصبح استخدام البيانات الضخمة وإنترنت الأشياء (IoT) عاملاً رئيسيًا في تحسين تجربة العمرة. من التنبؤ بحركة الحشود إلى تخصيص الخدمات بناءً على احتياجات المعتمرين، تتيح التقنيات الحديثة تقديم خدمات أكثر كفاءة ومرونة.
    في هذه المجموعة، سنركز على  كيفية استخدام البيانات الضخمة وإنترنت الأشياء لتحسين إدارة وتخطيط رحلات العمرة، بأمثلة واقعية لحلول ذكية مطبقة بالفعل من مختلف دول العالم، وكيف يمكن الاستفادة من تلك النماذج لتحسين تجربة العمرة بداية من التخطيط والسفر والانتظار في المطار وحتى إقامة المناسك والعودة.
    `,
    image: umrahSmartSolution,
    icon: <IOTIcon />,
    discussion_topics: [
      "كيف يمكن أن يساهم إنترنت الأشياء في تحسين خدمات العمرة؟",
      "ما دور البيانات الضخمة في تقديم تجربة أكثر تخصيصًا للمعتمرين؟",
      "كيف يمكن استخدام التكنولوجيا في تحسين إدارة الحشود والخدمات التشغيلية؟",
      "ما هي فرص الاستثمار في الحلول الذكية للعمرة للشركات؟"
    ],
    url: "umrah-smart-solutions"
  },

  {
    title: "⁠ابتكار حلول لوجستية للسفر الجوي لأداء العمرة",
    description: `
    يُعد السفر الجوي أحد الركائز الأساسية في رحلة العمرة، حيث يتطلب تنسيقًا عاليًا بين شركات الطيران، والمطارات، والجهات المنظمة لضمان تجربة سلسة للمعتمرين. مع ازدياد أعداد المعتمرين سنويًا، تبرز الحاجة إلى حلول لوجستية مبتكرة تُحسّن إدارة الرحلات، تقلل أوقات الانتظار، وتوفر خيارات سفر أكثر كفاءة ومرونة. التقنيات الحديثة مثل الذكاء الاصطناعي، والتحليلات المتقدمة، والتكامل بين أنظمة الحجز والنقل البري، تسهم في رفع كفاءة العمليات وتقديم تجربة أفضل للمعتمرين من لحظة الحجز وحتى الوصول.
    `,
    image: logistics,
    icon: <LogisticsIcon />,
    discussion_topics: [
      "كيف يمكن تحسين تجربة السفر الجوي للمعتمرين من خلال التحول الرقمي والتقنيات الحديثة؟",
      "ما هي أفضل الممارسات لإدارة الرحلات الموسمية للعمرة وتقليل الازدحام في المطارات؟",
      "ما الحلول الذكية التي يمكن أن تسرّع إجراءات السفر والجوازات؟",
      "كيف يمكن تحسين تجربة السفر الجوي من خلال الشراكات والابتكارات؟"
    ],
    url: "logistics"
  },
  {
    title: "تحسين النظم التشغيلية للعمرة",
    description: `
    تتطلب عمليات العمرة إدارة معقدة تشمل التأشيرات، الحجوزات، النقل، الإقامة، والخدمات الميدانية. يهدف تحسين النظم التشغيلية إلى تعزيز الكفاءة وتقليل الأخطاء وزيادة رضا المعتمرين، من خلال تبني التقنيات الحديثة مثل الأتمتة، والتحليلات المتقدمة، والذكاء الاصطناعي. تحسين التكامل بين الجهات المعنية، وتطوير أنظمة رقمية متكاملة، يسهم في تقديم خدمات أكثر سلاسة ومرونة، مما يعزز تجربة العمرة بشكل عام. 
    `,
    image: improvement,
    icon: <ImprovementIcon />,
    discussion_topics: [
      "كيف يمكن للتحول الرقمي أن يسهم في رفع كفاءة العمليات التشغيلية للعمرة؟",
      "ما دور التحول الرقمي والذكاء الاصطناعي في تقليل التكاليف وزيادة سرعة تنفيذ الإجراءات؟",
      "كيف يمكن تحسين التكامل بين أنظمة الحجوزات، التأشيرات، والنقل لضمان تجربة سلسة للمعتمرين؟",
      "ما هي أبرز التحديات التشغيلية التي تواجه قطاع العمرة، وكيف يمكن التغلب عليها باستخدام التكنولوجيا؟"
    ],
    url: "improvement"
  },
  {
    title: "تعزيز الابتكار وريادة الأعمال في العمرة",
    description: `
    يشهد قطاع العمرة تطورًا مستمرًا يتطلب حلولًا مبتكرة لمواكبة الاحتياجات المتزايدة لضيوف الرحمن. من خلال ريادة الأعمال والتقنيات الحديثة، يمكن تحسين الخدمات المقدمة، وتطوير حلول جديدة تساهم في تسهيل رحلة المعتمر. تركز هذه المجموعة على استكشاف بيئة عمل العمرة (Ecosystem)، وفهم دور الابتكار في مواجهة التحديات، وطرح أفكار ريادية تعزز من كفاءة العمليات وجودة الخدمات. كما ستشمل الجلسة جلسة عصف ذهني لتطوير مبادرات مبتكرة لحل المشكلات الرئيسية التي يواجهها المعتمرون.
    `,
    image: innovation,
    icon: <InnovationIcon />,
    discussion_topics: [
      "كيف يمكن لرواد الأعمال تقديم حلول مبتكرة تعزز من تجربة المعتمرين؟",
      "ما هي أبرز التحديات التي تواجه قطاع العمرة اليوم، وكيف يمكن للتقنيات الحديثة معالجتها؟",
      "كيف يمكن تطوير بيئة داعمة للابتكار والاستثمار في قطاع العمرة؟",
      "ما الخطوات العملية التي يمكن اتخاذها لتحويل الأفكار الريادية إلى مشاريع ناجحة في مجال العمرة؟"
    ],
    url: "innovation"
  },
  {
    title: "العمرة بلس + التسويق المباشر وتعزيز مفهوم البيع المباشر للعميل",
    description: `
    في عصر التحول الرقمي، أصبح التسويق المباشر أداة فعالة لتعزيز تجربة العمرة وجذب المعتمرين بأساليب أكثر تخصيصًا وتأثيرًا. يهدف مفهوم “العمرة بلس +” إلى تطوير استراتيجيات تسويقية تركز على البيع المباشر للعميل، مما يمكن شركات العمرة من تقديم عروض وخدمات مخصصة دون الحاجة إلى وسطاء. يشمل ذلك الاستفادة من التجارة الإلكترونية، والحجوزات عبر التطبيقات الذكية، وبرامج الولاء، مما يسهم في تحسين تجربة العميل وزيادة ولائه. كما أن استخدام التحليلات المتقدمة وتقنيات التسويق الرقمي يعزز القدرة على استهداف شرائح المعتمرين بدقة وكفاءة.
    `,
    image: umrahPlus,
    icon: <UmrahPlusIcon />,
    discussion_topics: [
      "كيف يمكن لشركات العمرة تبني استراتيجيات البيع المباشر لتعزيز ولاء العملاء وزيادة الإيرادات؟",
      "ما دور المنصات الرقمية في تسهيل حجز رحلات العمرة وتحسين تجربة المعتمر؟",
      "ما القنوات الأكثر فاعلية للتواصل مع العملاء المحتملين؟",
      "كيف يمكن تعزيز ثقة المعتمرين في الحجز المباشر عبر الإنترنت؟"
    ],
    url: "umrah-plus"
  },
  {
    title: "إدارة الحشود خارج الحرمين",
    description: `
    مع تزايد أعداد المعتمرين سنويًا، تصبح إدارة الحشود خارج الحرمين الشريفين تحديًا رئيسيًا لضمان سلامة وراحة الزوار. تتطلب هذه العملية حلولًا مبتكرة تجمع بين التحليل الذكي للبيانات، وأنظمة التوجيه الذكية، والتكامل بين وسائل النقل والبنية التحتية، لضمان تدفق سلس للحشود وتقليل الازدحام. استخدام تقنيات مثل الذكاء الاصطناعي، وإنترنت الأشياء، والمراقبة الفورية يمكن أن يساعد في تحسين توزيع الحشود، والاستجابة الفورية لحالات الطوارئ، وتعزيز تجربة العمرة بشكل عام.
    `,
    image: crowdManagement,
    icon: <CrowdManagementIcon />,
    discussion_topics: [
      "كيف يمكن توظيف التكنولوجيا الحديثة في تحسين إدارة الحشود خارج الحرمين؟",
      "ما هي أفضل الممارسات العالمية التي يمكن تطبيقها لتنظيم تدفق المعتمرين وتقليل الازدحام؟",
      "كيف يمكن تحسين تكامل أنظمة النقل مع خطط إدارة الحشود لتوفير تجربة أكثر سلاسة؟",
      "ما دور البيانات الضخمة والذكاء الاصطناعي في التنبؤ بحركة المعتمرين وتحسين توزيعهم؟"
    ],
    url: "crowd-management"
  },

  {
    title: "رفع مستوى خدمات العمرة (التميز في الضيافة)",
    description: `
    مع ازدياد التنافس في قطاع السياحة الدينية، أصبح التميز في الضيافة عنصرًا أساسيًا لجذب الزوار المميزين إلى السعودية. تركز هذه المجموعة على استكشاف الفرص المتاحة في السوق لخدمات VIP، وكيفية تقديم تجربة عمرة فاخرة تلبي احتياجات المسافرين الباحثين عن خدمات راقية. سنناقش آليات تصميم باقات ضيافة متميزة، وأفضل الممارسات في تخصيص تجربة العمرة، مع عرض نموذج ناجح في تقديم خدمات VIP لضيوف الرحمن.
    `,
    image: raisingUmrahService,
    icon: <RaisingUmrahLevelIcon />,
    discussion_topics: [
      "كيف يمكن تقديم تجربة عمرة فاخرة تناسب الزوار المميزين الباحثين عن خدمات راقية؟",
      "ما الفرص المتاحة في السوق لاستقطاب المسافرين من الفئات الراقية عبر خدمات VIP؟",
      "ما الخطوات الأساسية التي يجب على مقدمي الخدمات اتخاذها للتميز في الضيافة؟",
      "ما الدروس المستفادة من التجارب الناجحة في تقديم خدمات VIP للحجاج والمعتمرين؟"
    ],
    url: "raising-umrah-service-level"
  },
  {
    title: "أماكن الإقامة الشاملة (تلبية احتياجات كل زائر في المدينة المنورة)",
    description: `
    تعد جودة الإقامة عنصرًا أساسيًا في تحسين تجربة ضيوف الرحمن، حيث تتأثر خدمات الضيافة في المدينة المنورة بمعايير الامتثال، والتوازن بين العرض والطلب، والتحديات التنظيمية التي تواجه بعض الفنادق. تركز هذه المجموعة على مناقشة مدى التزام الفنادق بمعايير وزارة السياحة، والفجوة في سوق الضيافة، والتحديات التي تعيق تشغيل بعض المنشآت، بالإضافة إلى استعراض احتياجات المعتمرين من حيث الإقامة والخدمات الفندقية. كما سيتم استعراض الحلول المقترحة لتعزيز الامتثال وتحقيق تجربة إقامة أفضل لضيوف الرحمن.
    `,
    image: accommodations,
    icon: <UmrahAccommodationsIcon />,
    discussion_topics: [
      "كيف يؤثر مدى التزام الفنادق في مكة والمدينة بمعايير وزارة السياحة على جودة الخدمات المقدمة؟",
      "كيف يمكن تحقيق توازن بين العرض والطلب في سوق الضيافة لضمان استدامة تشغيل الفنادق؟",
      "ما أبرز التحديات التي تواجه الفنادق غير المتوافقة مع المتطلبات التنظيمية، وكيف يمكن معالجة هذه المشكلات؟",
      "ما الذي يبحث عنه المعتمرون والزوار عند اختيار أماكن إقامتهم، وكيف يمكن تحسين الخدمات الفندقية لتلبية احتياجاتهم؟"
    ],
    url: "accommodations"
  },
  {
    title: "تعزيز تجربة العمرة من خلال التكنولوجيا",
    description: `
    تلعب التكنولوجيا دورًا محوريًا في تحسين تجربة العمرة، من خلال حلول رقمية تسهّل الحجز، والتنقل، وإدارة الحشود. تتيح الأنظمة الذكية مثل التأشيرات الإلكترونية، والتطبيقات التفاعلية، والأساور الذكية، تحسين سلامة وراحة المعتمرين. كما تسهم البيانات الضخمة وإنترنت الأشياء في تحسين الخدمات وتوزيع الموارد بكفاءة. مع تزايد الاعتماد على الحلول الرقمية، يصبح تكامل الأنظمة بين الجهات المعنية ضرورة لضمان تجربة عمرة أكثر سلاسة وأمانًا.
    `,
    image: enhancingUmrahExperience,
    icon: <EnhancingUmrahIcon />,
    discussion_topics: [
      "كيف يمكن أن تساهم التكنولوجيا في تسهيل رحلة المعتمر؟",
      "ما هو دور منصة نسك في التسويق للعمرة بشكل أكثر فعالية، وحماية البيانات وتعزيز الأمن السيبراني؟",
      "كيف يمكن تطبيق التحول الرقمي الشامل في خدمات العمرة، وما هي فرص الاستثمار المتاحة؟",
      "ما الحلول التقنية التي يمكن تطبيقها لرفع جودة الخدمات المقدمة للمعتمرين؟"
    ],
    url: "enhancing-umrah-experience-through-technology"
  },
]