import { Link } from 'react-router-dom/cjs/react-router-dom'
import heroSecionBanner from "../assets/hero-section-bg.jpg"
import LogoImage from "../assets/logo.png"

export default function ZayaraForumHeader({ title }) {
  return (
    <section className="hero-section">
      <div className="hero-banner">
        <img src={heroSecionBanner} alt="umrah forum bg" height="644px" />
      </div>
      <div className="container d-flex flex-column justify-content-between align-items-center h-100">
        <Link to="/umrah-forum" className="align-self-baseline">
          <img src={LogoImage} alt="صفا سوفت منتدي العمرة و الزيارة" className="img-fluid logo" width="450" />
        </Link>

        <h1 className="header-title">
          {title}
        </h1>
        <span></span>
      </div>
    </section>
  )
}
