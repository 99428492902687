import { useWebBuilderState } from 'context/webBuilder';
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import usePreviewMode from "hooks/usePreviewMode";
import Locale from 'translations';

export default function TransferCardDefault(props) {
  const { selectedLanguage } = useWebBuilderState();
  const { marketPlace, productElements, Service_Builder } = Locale;
  const { isPreview } = usePreviewMode();

  
  const { openEditModal, image, country, name, name_localized, withDriver, vehicleCapacity } = props;  

  let countryName = country?.[selectedLanguage] || country?.en || country


  return (
    <div className="transfer-card">

      <div className="card-img">
        {!isPreview && !image ? (
          <button className="btn add-service-btn"
            onClick={() => openEditModal(props)}
          >
            <AddIcon />
          </button>
        ) : null}

        {image
          ? <img src={image} alt="transfer" width={100} />
          : null
        }
      </div>

      <div className="card-details">
        <h5 className="title">
          {name_localized ? name_localized?.[selectedLanguage] : name}
        </h5>
        <p className="location">
          <LocationIcon />
          <span className="mx-2">{countryName}</span>
        </p>
        <p className="location">
          <LocationIcon />
          <span className="mx-2">{productElements.capacity} {vehicleCapacity} {marketPlace.Adult}</span>
        </p>
        <p className="location">
          <LocationIcon />
          <span className="mx-2">{withDriver ? Service_Builder.with_driver : Service_Builder.without_driver}</span>
        </p>
      </div>
    </div>
  )
}
