import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import PaxInput from 'modules/UmrahOperations/shared/PaxInput';
import Locale from 'translations';
import validate from "helpers/validate";
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';
import { useSBSState } from 'context/global';




export default function OperationStatementVisa({
  OperationStatement,
  setOperationStatement,
  isDisabled,
  operationHasSent,
  submitProduct,
  formSubmition,
  errors,
	setErrors,
}) {
  const { productsBuilder, operationStatement } = Locale;
  const { isAuth } = useSBSState();
  const operation_details = OperationStatement.operation_details;
  const visaData = OperationStatement.visas;

  const { visaTypesLookup } = getOperationsLookup(Locale);

	console.log("OperationStatement",errors);
  
  function addNewVisa() {
    const isFirstVisaRow = visaData?.length === 0;
    const visaCLone = [...visaData];
    const newVisaObj = {
      visa_type: "",
      adults: isFirstVisaRow ? OperationStatement?.adults : 0,
      children: isFirstVisaRow ? OperationStatement?.children : 0,
      infants: isFirstVisaRow ? OperationStatement?.infants : 0,
      issued_by: "",
      nusuk_number: ""
    };
    setOperationStatement({ ...OperationStatement, visas: [...visaCLone, newVisaObj] });
  }

  function handleVisaInputs(value, keyName, visaIndex) {
    let visaClone = [...visaData];
    let visaItem = visaClone[visaIndex];
    visaItem = { ...visaItem, [keyName]: value }
    visaClone[visaIndex] = visaItem;
    setOperationStatement({ ...OperationStatement, visas: visaClone });
    setErrors({
      ...errors,
      ...validate(
        { name: `${keyName}-${visaIndex}`, value: visaItem[keyName] },
        {
          required: true,
          maxNumber: keyName === "adults" ? +OperationStatement?.adults : false,
        }
      ),
    })
  }


  function deleteVisa(visaIndex) {
    let visaClone = [...visaData];
    visaClone.splice(visaIndex, 1);
    setOperationStatement({ ...OperationStatement, visas: visaClone });
  }



  return (
    <div >
      {visaData?.map((visaItem, index) => {
        return (
          <div className="row mx-0 visa-item flex-wrap" key={`visa-${index}`}>
            <div className="col-md-3">
              <SelectField
                label={operationStatement.VisaType}
                id="visa_type"
                name="visa_type"
                options={visaTypesLookup}
                value={visaItem.visa_type?.label}
                onChange={(e) => handleVisaInputs(e, "visa_type", index)}
                color={errors?.[`visa_type-${index}`]?.required ? "danger" : ""}
                errors={errors?.[`visa_type-${index}`]}
                disabled={operationHasSent}
              />
            </div>
            {/* Adults */}
            <div className="col-12 col-md-2 col-sm-6">
              <PaxInput
                title={operationStatement.Pax}
                paxTypeText={operationStatement.Adults}
                value={visaItem?.adults}
                handleIncrease={() => handleVisaInputs(+visaItem.adults + 1, "adults", index)}
                handleDecrease={() => handleVisaInputs(+visaItem.adults - 1, "adults", index)}
                onChange={(value) => handleVisaInputs(value, "adults", index)}
                maxPax={OperationStatement?.adults}
                color={errors?.[`adults-${index}`]?.maxNumber || errors?.[`adults-${index}`]?.required}
                errors={errors?.[`adults-${index}`]}
                operationHasSent={operationHasSent}

              />
            </div>
            {/* Children */}
            <div className="col-12 col-md-2 col-sm-6">
              <PaxInput
                paxTypeText={operationStatement.Children}
                value={visaItem?.children}
                handleIncrease={() => handleVisaInputs(+visaItem.children + 1, "children", index)}
                handleDecrease={() => handleVisaInputs(+visaItem.children - 1, "children", index)}
                onChange={(value) => handleVisaInputs(value, "children", index)}
                operationHasSent={operationHasSent}

              />
            </div>
            {/* Infant */}
            <div className="col-12 col-md-2 col-sm-6">
              <PaxInput
                paxTypeText={operationStatement.Infant}
                value={visaItem?.infants}
                handleIncrease={() => handleVisaInputs(+visaItem.infants + 1, "infants", index)}
                handleDecrease={() => handleVisaInputs(+visaItem.infants - 1, "infants", index)}
                onChange={(value) => handleVisaInputs(value, "infants", index)}
                operationHasSent={operationHasSent}
              />
            </div>
            {/* Issued by */}
            <div className="col-md-3">
              <TextField
                label={operationStatement.Issuedby}
                placeholder={operationStatement.Issuedby}
                name="issued_by"
                id="issued_by"
                value={visaItem.issued_by}
                onChange={(e) => handleVisaInputs(e.target.value, "issued_by", index)}
                // color={errors?.[`issued_by-${index}`]?.required ? "danger" : ""}
                // errors={errors?.[`issued_by-${index}`]}
                disabled={operationHasSent}

              />
            </div>
            {/* Nusuk Numbers */}
            <div className="col-12 mt-3">
              <TextField
                label={operationStatement.NusukNumbers}
                placeholder={operationStatement.TyprNusuknumbers}
                name="nusuk-number"
                id="nusuk-number"
                value={visaItem.nusuk_number}
                onChange={(e) => handleVisaInputs(e.target.value, "nusuk_number", index)}
                disabled={operationHasSent}

              />
            </div>
            {!operationHasSent ?
              <button className='btn px-1 py-0 mt-2 btn-Delete-operation' onClick={() => deleteVisa(index)}>
                <i className="far fa-trash-alt me-1" />
                {operationStatement.Delete}
              </button>
              : null}
          </div>
        )
      })}


      {!operationHasSent ?
        <button className='btn-plus-operation px-0 mt-3'
          onClick={() => addNewVisa()}
        >
          <span className='me-1 ms-0'><i class="fas fa-plus"></i></span>
          {operationStatement.AddVisa}
        </button>
        : null}

      {!operationHasSent ?
        <div className="w-100 mt-3 gap-1 d-flex justify-content-end">
          <button
            className="btn-Product-Filter bg-nxt justify-content-center"
            onClick={() => formSubmition({ OperationStatement })}
            disabled={visaData?.length === 0 || isDisabled}
          >
            {productsBuilder.Save}
          </button>
          {!isAuth &&
            <button
              className="btn-Product-Filter bg-nxt justify-content-center"
              onClick={() => {
                // setSaveTabName("operation-hotels")
                submitProduct();
              }}
              disabled={!operation_details?.statement_type?.id}
            >
              {productsBuilder.submit}
            </button>
          }
        </div>
        : null}
    </div>
  )
}
