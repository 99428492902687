// import axios from 'axios';
import jsPDF from 'jspdf';

export function formatPrice(price, options = {}) {
  if (!price) return "0,00";

  const defaultOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const formatter = new Intl.NumberFormat("en-US", { ...defaultOptions, ...options });
  return formatter.format(price || 0);


}

export const capitalizeName = (name) => {
  if (!name) return '';

  const withSpaces = name.replace(/_/g, ' ');

  const capitalized = withSpaces
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  return capitalized;
};


export const downloadDocument = (response, fileName) => {
  try {
    if (response && response.data) {
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log(`No data received for file: ${fileName}`);
    }
  } catch (error) {
    console.log("Error processing download", error);
  }
};





export const generatePDF = async (data, headers, dataAccessors, filename = 'document.pdf', title = '') => {
  try {
    if (!data || data.length === 0) {
      console.log('No data available.');
      return;
    }

    const doc = new jsPDF('p', 'pt', 'a4');
    const element = document.createElement('div');

    let tableHTML = `
          <style>
              table {
                  width: 100%;
                  border-collapse: collapse;
                  font-size: 5px;
              }
              th, td {
                  border: 1px solid #dddddd;
                  text-align: left;
                  padding: 5px;
                  word-wrap: break-word;
              }
              th {
                  background-color: #f2f2f2;
              }
              td {
                  max-width: 150px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
              }
              h1 {
                  font-size: 16px;
                  text-align: center;
                  margin-bottom: 10px;
              }
          </style>
          ${title ? `<h1>${title}</h1>` : ''}
          <table>
              <thead>
                  <tr>
      `;

    headers.forEach(header => {
      tableHTML += `<th>${header}</th>`;
    });

    tableHTML += `</tr></thead><tbody>`;

    data.forEach(item => {
      tableHTML += `<tr>`;
      dataAccessors.forEach(accessor => {
        const value = accessor(item) || 'N/A';
        tableHTML += `<td>${value}</td>`;
      });
      tableHTML += `</tr>`;
    });

    tableHTML += `</tbody></table>`;

    element.innerHTML = tableHTML;

    doc.html(element, {
      callback: (pdf) => {
        pdf.save(filename);
      },
      x: 10,
      y: 10,
      html2canvas: {
        scale: 2,
      }
    });
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};


export const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
  });
};

export const handleFileURL = (uri) =>{
  if(!uri) return null;
  else{
    if(String(uri).includes("http://") || String(uri).includes("https://"))
      return uri;
    return process.env.REACT_APP_FILEUPLOAD_URL + '/fetch/' + uri;
  }
}

/** 
 * @param {string | object} urlObj - the value of logo, may be a string or an object of selected languages
 * @param {string | undefined} url - the value of selected language url.
 * @returns {ReturnType} - Description of the return value (optional).
 */

export const logoURL = (urlObj, url) =>{
  if(!urlObj) return null;
  if(typeof urlObj === 'string'){
    return handleFileURL(urlObj);
  }
  return handleFileURL(url);
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export const jsonBeautify = (json)=> {
  try {
    return JSON.stringify(json, null, 2);
  } catch (error) {
    throw new Error("invalid json");
    
  }
}
