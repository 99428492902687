import SelectField from "components/Form/SelectField/SelectField";
import Locale from "translations";
import validate from "helpers/validate";
import TextField from "components/Form/TextField/TextField";
import DeleteIcon from "modules/products-builder/Pricing/IconsSVG/DeleteIcon";
import HotelIcon from "modules/products-builder/Pricing/IconsSVG/HotelIcon";
import { getOperationsLookup } from "modules/UmrahOperations/helper/operationsLookup";
import NumberField from 'components/Form/NumberField/NumberField';
import { useSBSState } from 'context/global';


export default function OperationStatementCatering({
	operationHasSent,
	OperationStatement,
	setOperationStatement,
	isDisabled,
	submitProduct,
	formSubmition,
	errors,
	setErrors,
}) {
	const catering = OperationStatement.catering;
	const { isAuth } = useSBSState();

	const { operationStatement, productsBuilder, productElements } = Locale;
	const { mealsTypesLookup } = getOperationsLookup(Locale);

	const operation_details = OperationStatement.operation_details;

	function addMeal(index) {
		let caters = [...catering];
		caters[index].catering.push({});
		setOperationStatement({
			...OperationStatement,
			catering: caters,
		});
	}

	function changeCateringValue(value, key, indexCatering, index) {
		let cateringData = [...catering];
		cateringData[indexCatering].catering[index] = {
			...cateringData[indexCatering].catering[index],
			[key]: value,
		};
		setOperationStatement({ ...OperationStatement, catering: cateringData });
		setErrors({
			...errors,
			...validate(
				{
					name: key + indexCatering + index,
					value: value,
				},
				{ required: true }
			),
		})
	}


	function deleteCatering(indexCatering, indx) {
		let cateringData = [...catering];
		cateringData[indexCatering].catering = cateringData[
			indexCatering
		].catering.filter((res, index) => index !== indx);
		setOperationStatement({ ...OperationStatement, catering: cateringData });
	}

	return (
		<div>
			<div className="w-100">
				<label className="w-100" htmlFor="">
					{" "}
					{operationStatement.Cateringtype}
				</label>
				<div class="form-check form-check-inline">
					<input
						class="form-check-input"
						type="radio"
						name="inlineRadioOptions"
						id="Include"
						checked={+OperationStatement?.catering_type === 0}
						onChange={(e) => {
							setOperationStatement({
								...OperationStatement,
								catering_type: e.target.value,
							});
						}}
						value="0"
						className="mx-1"
						disabled={operationHasSent}
					/>
					<label class="form-check-label" for="Include">
						{operationStatement.Includeaccommodation}
					</label>
				</div>
				<div class="form-check form-check-inline">
					<input
						class="form-check-input"
						type="radio"
						name="inlineRadioOptions"
						id="Catering"
						value="1"
						className="mx-1"
						checked={+OperationStatement?.catering_type === 1}
						onChange={(e) => {
							setOperationStatement({
								...OperationStatement,
								catering_type: e.target.value,
							});
						}}
						disabled={operationHasSent}

					/>
					<label class="form-check-label" for="Catering">
						{operationStatement.CateringProvider}
					</label>
				</div>
			</div>
			{OperationStatement?.catering_type == 1 ? (
				<div className="item-row mt-3">
					<div className="row mx-0 p-2">
						<div className="col-md-5 px-3">
							<div className="row">
								<div className="col-md-6 col-12">
									<label htmlFor="" className="label">
										{operationStatement.Hotels}
									</label>
								</div>
								<div className="col-md-3 col-12">
									<label htmlFor="" className="label">
										{operationStatement.City}
									</label>
								</div>
								<div className="col-md-3 col-12">
									<label htmlFor="" className="label">
										{operationStatement.Nights}
									</label>
								</div>
							</div>
						</div>
						<div className="col-md-5 px-3">
							<div className="row">
								<div className="col-md-2 col-12">
									<label htmlFor="" className="label">
										{operationStatement.Pax}
									</label>
								</div>
								<div className="col-md-5 col-12">
									<label htmlFor="" className="label">
										{operationStatement.MealsType}
									</label>
								</div>
								<div className="col-md-5 col-12">
									<label htmlFor="" className="label">
										{operationStatement.Supplier}
									</label>
								</div>
							</div>
						</div>
					</div>
					{OperationStatement?.catering &&
						OperationStatement?.catering?.length > 0
						? OperationStatement?.catering?.map((cater, index) => {
							return (
								<div key={index} className="row mx-0 p-2">
									<div className="col-md-5 px-3">
										<div className="row" style={{ height: "100%" }}>
											<div className="col-md-6 col-12">
												<label
													htmlFor=""
													className="label d-flex height-center-available height-fill-available justify-content-start m-0 data-label text-start"
													title={cater?.hotel?.name}
												>
													<span className="mx-1">
														<HotelIcon size={"24"} />
													</span>{" "}
													<span className="d-flex align-items-center">
														{cater?.hotel?.name.length > 18
															? cater?.hotel?.name.slice(0, 18) + "..."
															: cater?.hotel?.name}
													</span>
												</label>
											</div>
											<div className="col-md-3 col-12">
												<label
													htmlFor=""
													className="label d-flex height-center-available height-fill-available m-0 data-label"
												>
													{cater?.city_id?.name}
												</label>
											</div>
											<div className="col-md-3 col-12">
												<label
													htmlFor=""
													className="label d-flex height-center-available height-fill-available m-0 data-label"
												>
													{cater?.nights}
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-5 px-3">
										<div className="row  flex-column">
											{cater?.catering && cater?.catering?.length > 0
												? cater.catering.map((cate, Ind) => {
													return (
														<div className="d-flex flex-md-nowrap flex-wrap" key={`cate-${Ind}`}>
															<div className="col-md-2 col-12">
																<div className="form-group full-input text-center-input">
																	<NumberField
																		placeholder={operationStatement.pax}
																		label={operationStatement.pax}
																		hasLabel={false}
																		removeArrow={true}
																		name="pax"
																		max={+OperationStatement?.adults}
																		value={cate?.pax}
																		onChange={(e) => {
																			changeCateringValue(
																				e.target.value,
																				"pax",
																				index,
																				Ind
																			);
																		}}
																		color={
																			errors["pax" + index + Ind]?.required || errors["pax" + index + Ind]?.maxNumber
																				? "danger"
																				: ""
																		}
																		errors={errors["pax" + index + Ind]}
																	/>
																</div>
															</div>
															<div className="col-md-5 col-12">
																<div className="form-group full-input">
																	<SelectField
																		label={productElements.mealsType}
																		value={cate?.meal_type?.name}
																		hasLabel={false}
																		onChange={(e) => {
																			changeCateringValue(
																				e,
																				"meal_type",
																				index,
																				Ind
																			);
																		}}
																		options={mealsTypesLookup}
																		placeholder={productsBuilder.select}
																		color={
																			errors["meal_type" + index + Ind]?.required
																				? "danger"
																				: ""
																		}
																		errors={errors["meal_type" + index + Ind]}
																	/>
																</div>
															</div>
															<div className="col-md-5 col-12">
																<TextField
																	placeholder={
																		operationStatement.TypeSupplierName
																	}
																	label={
																		operationStatement.TypeSupplierName
																	}
																	hasLabel={false}
																	name="supplier_name"
																	value={cate?.supplier_name}
																	onChange={(e) => {
																		changeCateringValue(
																			e.target.value,
																			"supplier_name",
																			index,
																			Ind
																		);
																	}}
																	color={
																		errors["supplier_name" + index + Ind]
																			?.required
																			? "danger"
																			: ""
																	}
																	errors={
																		errors["supplier_name" + index + Ind]
																	}
																/>
															</div>

															{Ind > 0 ? (
																<div
																	className="col-md-3"
																	style={{ height: "42px" }}
																>
																	<div className="d-flex ms-3 h-100">
																		{Ind !== 0 ? (
																			<button
																				className="btn px-1 py-0 font-16"
																				onClick={() =>
																					deleteCatering(index, Ind)
																				}
																			>
																				<DeleteIcon />
																			</button>
																		) : null}
																	</div>
																</div>
															) : (
																<div
																	className="col-md-3"
																	style={{ height: "42px" }}
																>
																	<button
																		className="btn-plus-operation mt-1 d-flex align-items-center flex-nowrap"
																		onClick={() => addMeal(index)}
																	>
																		<span>
																			<i class="fas fa-plus"></i>
																		</span>
																		{operationStatement.AddMeal}
																	</button>
																</div>
															)}
														</div>
													);
												})
												: null}
										</div>
									</div>
								</div>
							);
						})
						: null}
				</div>
			) : null}
			{!operationHasSent ?
				<div className="w-100 mt-3 gap-1 d-flex justify-content-end">
					<button
						className="btn-Product-Filter bg-nxt justify-content-center"
						onClick={() => formSubmition({ OperationStatement })}
						disabled={isDisabled}
					>
						{productsBuilder.Save}
					</button>
					{!isAuth &&
						<button
							className="btn-Product-Filter bg-nxt justify-content-center"
							onClick={() => {
								// setSaveTabName("operation-hotels")
								submitProduct();
							}}
							disabled={!operation_details?.statement_type?.id}
						>
							{productsBuilder.submit}
						</button>
					}
				</div>
				: null}
		</div>
	);
}
