import { arrayToObject } from './formatPageData';
import { isObject } from "lodash";



// adding new services to content home page
export function addingServiceToContent(content = [], newServices = [], languagesList, pagesData) {
  const toursSectionIndex = content.findIndex(i => i?.type === "tours");
  const languagesCodes = languagesList.map(l => l?.code);
  for (let i = 0; i < newServices.length; i++) {
    const service = newServices[i];
    const contentHasServiceType = content?.find(i => i?.type === service.type);
    if (!contentHasServiceType && toursSectionIndex > -1) {
      content.splice(toursSectionIndex + 1, 0, {
        "type": `${service.type}`,
        "title": arrayToObject(languagesCodes, isObject(service?.title) ? service?.title : {}, service?.title),
        "body": arrayToObject(languagesCodes, isObject(service?.title) ? service?.title : {}, service?.title),
        "id": `${service.type}-section`,
        "items": []
      });
    }
  }

  return content;
}

export function addingServiceToInnerPagesContent(pagesData) {
  let pagesHasContent = pagesData?.filter(i => i?.content?.length > 0);
  // apply new service inside inner pages
  for (let i = 0; i < pagesHasContent.length; i++) {
    let pageContent = pagesHasContent[i]?.content;
    const pageHasFlightService = pageContent.find(s => s?.id === "flight-section");
    const pageHasTransferService = pageContent.find(s => s?.id === "transfer-section");
    const pageHasOtherService = pageContent.find(s => s?.id === "otherService-section");

    pageContent = [
      ...pageContent,
      ...!pageHasFlightService ? [{
        id: "flight-section",
        type: "flights",
        items: [],
      }] : [],
      ...!pageHasTransferService ? [{
        id: "transfer-section",
        type: "transfer",
        items: [],
      }] : [],
      ...!pageHasOtherService ? [{
        id: "otherService-section",
        type: "otherService",
        items: [],
      }] : [],
    ];

    pagesHasContent[i].content = pageContent;
  }
  return pagesData;
}